import { type Components } from '@mui/material'

const getComponents: Components = {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: '#BDBDBD',
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        border: '1px solid #E0E0E0',
        borderRadius: 4,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        '&.MuiTableCell-head': {
          color: '#233A48',
        },
        '&.MuiTableCell-body': {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '21px',
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 24,
        height: 14,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
          },
        },
      },
      switchBase: {
        padding: 4,
        '&.Mui-checked': {
          transform: 'translateX(10px)',
          '& + .MuiSwitch-track': {
            opacity: 1,
            borderColor: '#FBCF24',
            backgroundColor: 'transparent',
          },
          '& > .MuiSwitch-thumb': {
            borderColor: '#FBCF24',
          },
        },
      },
      thumb: {
        width: 6,
        height: 6,
        boxSizing: 'border-box',
        border: '2px solid #828282',
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      track: {
        opacity: 1,
        backgroundColor: 'transparent',
        border: '1.5px solid #828282',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
        color: '#2C2A2A',
      },
      colorDefault: {
        backgroundColor: '#FFFFFF',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        '@media (min-width: 600px)': {
          minHeight: 80,
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '& > .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '21px',
        color: '#06103E',
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          marginTop: 8,
        },
        '& .MuiStepLabel-labelContainer': {
          color: '#06103E',
          '& .MuiStepLabel-label.Mui-completed': {
            color: '#06103E',
            fontWeight: 400,
          },
        },
        '& .MuiStepIcon-root': {
          color: '#FBCF24',
          '&.Mui-completed': {
            color: '#6FCF97',
          },
        },
        '& .MuiStepIcon-text': {
          fill: '#ffffff',
          fontWeight: 500,
        },
        '& .MuiStepLabel-alternativeLabel.Mui-active': {
          color: '#06103E',
          fontWeight: 400,
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        left: 'calc(-50% + 12px)',
        right: 'calc(50% + 12px)',
        '.MuiStepConnector-line': {
          borderColor: '#FBCF24',
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: '#EAEAEA',
        '&:after, &:before': {
          borderColor: '#EAEAEA',
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      focusRipple: false,
      disableRipple: true,
    },
    variants: [
      {
        props: { variant: 'contained', color: 'error' },
        style: {
          color: '#ffffff',
          '&:hover, &:active, &:visited, &:focus': {
            boxShadow: 'none',
            backgroundColor: '#EB5757',
          },
        },
      },
      {
        props: { variant: 'contained', color: 'success' },
        style: {
          color: '#ffffff',
          '&:hover, &:active, &:visited, &:focus': {
            boxShadow: 'none',
            backgroundColor: '#6FCF97',
          },
        },
      },
      {
        props: { variant: 'contained', color: 'primary' },
        style: {
          color: '#ffffff',
          '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            top: 'calc(50% - 115px/2 - 0.5px)',
            left: 'calc(50% - 115px/2 + 0.5px)',
            right: 0,
            bottom: 0,
            width: 115,
            height: 115,
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '100%',
            transform: 'scale(0)',
            transitionProperty: 'transform',
            transitionDuration: '0.3s',
            transitionTimingFunction: 'ease-out',
          },
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#FBCF24',
            '&:before': {
              transform: 'scale(1)',
            },
          },
          '&:focus': {
            boxShadow: 'none',
            backgroundColor: '#FBCF24',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#FBCF24',
            '&:before': {
              transform: 'scale(1)',
            },
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          border: '1px solid #FBCF24',
          '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            top: 'calc(50% - 115px/2 - 0.5px)',
            left: 'calc(50% - 115px/2 + 0.5px)',
            right: 0,
            bottom: 0,
            width: 115,
            height: 115,
            backgroundColor: 'rgba(251, 207, 36, 0.1)',
            borderRadius: '100%',
            transform: 'scale(0)',
            transitionProperty: 'transform',
            transitionDuration: '0.3s',
            transitionTimingFunction: 'ease-out',
          },
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'rgba(251, 207, 36, 0.1)',
            '&:before': {
              transform: 'scale(1)',
            },
          },
          '&:focus': {
            boxShadow: 'none',
            backgroundColor: 'rgba(251, 207, 36, 0.1)',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: 'rgba(251, 207, 36, 0.1)',
            '&:before': {
              transform: 'scale(1)',
            },
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'error' },
        style: {
          border: '1px solid #EB5757',
          '&:hover, &:active, &:visited, &:focus': {
            boxShadow: 'none',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        fontSize: 16,
        lineHeight: '24px',
        padding: '14px 18px',
        maxWidth: 160,
        width: '100%',
        boxShadow: 'none',
        textTransform: 'capitalize',
        transform: 'perspective(1px) translateZ(0)',
        overflow: 'hidden',
        position: 'relative',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: 14,
        lineHeight: '21px',
        fontWeight: 400,
        marginLeft: 0,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        position: 'relative',
        transform: 'none',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '24px',
        color: '#233A48',
        marginBottom: 4,
        '& .MuiFormLabel-asterisk': {
          color: '#CF0909',
        },
        '&.Mui-focused': {
          color: '#233A48',
        },
        '&.Mui-error': {
          color: '#233A48',
        },
        '&.Mui-disabled': {
          color: '#BDBDBD',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        backgroundColor: '#F8F8F8',
        border: '1px solid',
        borderColor: '#F1F1F1',
        transition: 'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&.Mui-focused': {
          borderColor: 'rgba(0, 0, 0, 0.2)',
        },
        '& .MuiInputBase-input': {
          padding: '13px 20px 13px 20px',
          color: '#233A48',
          '&::placeholder': {
            color: 'rgba(0, 0, 0, 0.2)',
          },
        },
        '&.Mui-error': {
          backgroundColor: 'rgba(235, 87, 87, 0.05)',
          borderColor: '#EB5757',
        },
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.2)',
          backgroundColor: ' #F1F1F1',
        },
        '& .MuiSelect-icon': {
          color: '#233A48',
        },
      },
      adornedEnd: {
        '& .MuiInputBase-input': {
          paddingRight: 0,
        },
      },
      multiline: {
        padding: 0,
      },
    },
  },
  MuiPaper: {
    variants: [
      {
        props: { elevation: 1 },
        style: {
          boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.06)',
        },
      },
    ],
  },
  MuiAvatar: {
    variants: [
      {
        props: { variant: 'square' },
        style: {
          width: 32,
          height: 32,
          borderRadius: 4,
          backgroundColor: '#FBCF24',
          fontSize: 14,
          fontWeight: 500,
        },
      },
    ],
  },
}

export default getComponents
