import React, { useState, useEffect } from 'react'
import baseUrl from '../../../config/baseUrl'
import { Paper, Grid, Typography, Box } from '@mui/material'
import Pagination from '../../../components/Pagination'
import { useSnackbar } from '../../../components/SnackbarProvider'
const TransactionHistory = () => {
  const userId = sessionStorage.getItem('userId')
  const [transactions, setTransactions] = useState([])
  const showSnackbar = useSnackbar()

  // pagination
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getTransactions()
  }, [])

  const getTransactions = async () => {
    try {
      const res = await fetch(
        `${baseUrl}/api/admin/userpaymenthistory/${userId}?page=${currentPage}`,
      )
      const data = await res?.json()

      if (!res.ok) {
        // console.log(data.description)
        showSnackbar(data?.message, 'error')
        return
      }

      // console.log(data)
      setTransactions(data?.ordersData)
      setTotalPages(data?.totalPages)
    } catch (error) {
      // console.log(error)
      showSnackbar(error?.message, 'error')
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  return (
    <Box>
      <div style={{ overflowX: 'auto' }}>
        <Box sx={{ minWidth: '650px' }}>
          <Paper sx={{ width: '100%' }}>
            <Grid container sx={{ padding: '8px 4px' }}>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                  Sr.No
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                  Plan Name
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' align='right'>
                  Amount Paid
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' align='center'>
                  Payment Id
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' align='center'>
                  Subscription Date
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        <Box sx={{ minWidth: '650px', padding: '2px' }}>
          {transactions &&
            transactions?.map((transaction, index) => {
              return (
                <Paper sx={{ width: '100%' }} key={index}>
                  <Grid container sx={{ padding: '8px 4px' }}>
                    <Grid item xs={1}>
                      <Typography variant='subtitle1'>{index + 1}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant='subtitle1'>{transaction?.planName}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant='subtitle1' align='right'>
                        {transaction?.paymentAmount && (
                          <>
                            {'\u20B9'} {Number(transaction?.paymentAmount).toLocaleString('en-US')}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant='subtitle1' align='center'>
                        {transaction?.paymentId}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant='subtitle1' align='center'>
                        {transaction?.paymentCreatedDate &&
                          new Date(transaction.paymentCreatedDate).toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                          })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              )
            })}
          {transactions?.length === 0 && (
            <Typography variant='body1' mt='8px'>
              No Transactions yet
            </Typography>
          )}
        </Box>
      </div>

      {/* pagination */}
      <Grid
        container
        style={{
          display: 'flex',
          overflow: 'hidden',
          // position: 'fixed',
          // bottom: 5,
          // marginBottom: '20px',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
          padding: '0 0 10px 10px',
          lineHeight: '50px',
          zIndex: 11,
        }}
      >
        {transactions?.length > 10 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </Grid>
    </Box>
  )
}

export default TransactionHistory
