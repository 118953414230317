import * as yup from 'yup'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
})

const registerSchema = yup.object().shape({
  firstName: yup
    .string()
    .transform((value: string) => {
      if (!value) return value

      // Trim spaces, replace multiple spaces with single space, and capitalize each word
      return value
        .trim()
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .toLowerCase()
        .split(' ') // Split words
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1), // Capitalize first letter
        )
        .join(' ') // Join words back
    })
    .matches(/^[A-Za-z\s]+$/, 'First Name should contain only alphabetic characters and spaces')
    .min(1, 'First Name should be at least 1 character')
    .max(35, 'First Name should be no more than 35 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .transform((value: string) => {
      if (!value) return value

      // Trim spaces, replace multiple spaces with single space, and capitalize each word
      return value
        .trim()
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .toLowerCase()
        .split(' ') // Split words
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1), // Capitalize first letter
        )
        .join(' ') // Join words back
    })
    .matches(/^[A-Za-z\s]+$/, 'Last Name should contain only alphabetic characters and spaces')
    .min(1, 'Last Name should be at least 1 character')
    .max(35, 'Last Name should be no more than 35 characters')
    .required('Last Name is required'),
  contactNo: yup
    .string()
    .defined()
    .strict(true)
    .required('Phone number is required')
    .matches(phoneRegExp, 'Phone number is not valid')
    .max(10, 'Phone Number should be no more than 10 digits'),
  email: yup.string().email('Invalid email address').required(),
  state: yup.string().required('State is Required'),
  // password: yup.string().min(8).max(32).matches(/^\S*$/, 'Password should not contain spaces').required(),
  city: yup.string().required('City is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password cannot be longer than 32 characters')
    .matches(/^\S*$/, 'Password should not contain spaces')
    .test('has-uppercase', 'Password must contain at least 1 uppercase character', (value) => {
      if (!value) return false // Check for value being undefined or empty
      return /[A-Z]/.test(value)
    })

    .test('has-lowercase', 'Password must contain at least 1 lowercase character', (value) => {
      if (!value) return false // Check for value being undefined or empty
      return /[a-z]/.test(value)
    })
    .test(
      'has-special-characters',
      'Password must contain at least 1 special character',
      (value) => {
        if (!value) return false // Check for value being undefined or empty
        return /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(value)
      },
    )
    .test(
      'max-special-characters',
      'Password can contain at most 2 special characters',
      (value) => {
        if (!value) return true // If value is undefined, it's considered valid
        const specialCharacterCount = (value.match(/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/g) ?? []).length
        return specialCharacterCount <= 2
      },
    )
    .required(),
  confirmPassword: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password cannot be longer than 32 characters')
    .matches(/^\S*$/, 'Password should not contain spaces')
    .test('has-uppercase', 'Password must contain at least 1 uppercase character', (value) => {
      if (!value) return false // Check for value being undefined or empty
      return /[A-Z]/.test(value)
    })
    .test('has-lowercase', 'Password must contain at least 1 lowercase character', (value) => {
      if (!value) return false // Check for value being undefined or empty
      return /[a-z]/.test(value)
    })
    .test(
      'has-special-characters',
      'Password must contain at least 1 special character',
      (value) => {
        if (!value) return false // Check for value being undefined or empty
        return /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(value)
      },
    )
    .test(
      'max-special-characters',
      'Password can contain at most 2 special characters',
      (value) => {
        if (!value) return true // If value is undefined, it's considered valid
        const specialCharacterCount = (value.match(/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/g) ?? []).length
        return specialCharacterCount <= 2
      },
    )
    .required(),
})

const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email('Invalid email address').required(),
})

const changePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup
    .string()
    .min(8, 'New Password must be at least 8 characters')
    .max(32, 'New Password cannot be longer than 32 characters')
    .matches(/^\S*$/, 'New Password should not contain spaces')
    .test('has-uppercase', 'New Password must contain at least 1 uppercase character', (value) => {
      if (!value) return false // Check for value being undefined or empty
      return /[A-Z]/.test(value)
    })
    .test('has-lowercase', 'New Password must contain at least 1 lowercase character', (value) => {
      if (!value) return false // Check for value being undefined or empty
      return /[a-z]/.test(value)
    })
    .test(
      'has-special-characters',
      'New Password must contain at least 1 special character',
      (value) => {
        if (!value) return false // Check for value being undefined or empty
        return /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(value)
      },
    )
    .test(
      'max-special-characters',
      'New Password can contain at most 2 special characters',
      (value) => {
        if (!value) return true // If value is undefined, it's considered valid
        const specialCharacterCount = (value.match(/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/g) ?? []).length
        return specialCharacterCount <= 2
      },
    )
    .required(),
  confirmPassword: yup
    .string()
    .min(8, 'Confirm Password must be at least 8 characters')
    .max(32, 'COnfirm Password cannot be longer than 32 characters')
    .matches(/^\S*$/, 'Confirm Password should not contain spaces')
    .test(
      'has-uppercase',
      'Confirm Password must contain at least 1 uppercase character',
      (value) => {
        if (!value) return false // Check for value being undefined or empty
        return /[A-Z]/.test(value)
      },
    )
    .test(
      'has-lowercase',
      'Confirm Password must contain at least 1 lowercase character',
      (value) => {
        if (!value) return false // Check for value being undefined or empty
        return /[a-z]/.test(value)
      },
    )
    .test(
      'has-special-characters',
      'Confirm Password must contain at least 1 special character',
      (value) => {
        if (!value) return false // Check for value being undefined or empty
        return /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(value)
      },
    )
    .test(
      'max-special-characters',
      'Confirm Password can contain at most 2 special characters',
      (value) => {
        if (!value) return true // If value is undefined, it's considered valid
        const specialCharacterCount = (value.match(/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/g) ?? []).length
        return specialCharacterCount <= 2
      },
    )
    .required(),
})

const likeFeedbackSchema = yup.object().shape({
  message: yup.string().required(),
})

const editChatTitleSchema = yup.object().shape({
  title: yup
    .string()
    .required('Title is required')
    .test('is-not-blank', 'The field cannot be blank', function (value) {
      return value !== undefined && value.trim().length > 0
    }),
})

const disLikeFeedbackSchema = yup.object().shape({
  message: yup.string().required(),
  feedback: yup.array().defined().strict(true),
})

const contactSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  contactNo: yup
    .string()
    .required('Phone number is required')
    .defined()
    .strict(true)
    .matches(phoneRegExp, 'Phone number is not valid'),
  email: yup.string().email().required('Email is required'),
  zipCode: yup
    .string()
    .required('Zip code is required')
    .defined()
    .strict(true)
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be at least 5 digits')
    .max(6, 'Must be exactly 6 digits'),
  country: yup.string().required('Country is required'),
  message: yup
    .string()
    // .required('Description is required')
    .defined()
    .strict(true)
    .max(1000, 'Description must not exceed 1000 character'),
})

const ResetPasswordSchema = yup.object().shape({
  newPassword: yup.string().min(8).max(32).required(),
  confirmPassword: yup.string().min(8).max(32).required(),
})

export {
  contactSchema,
  changePasswordSchema,
  editChatTitleSchema,
  loginSchema,
  registerSchema,
  likeFeedbackSchema,
  disLikeFeedbackSchema,
  forgotPasswordSchema,
  ResetPasswordSchema,
}
