import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  Stack,
  Divider,
  List,
  CircularProgress,
} from '@mui/material'
import { UploadIcon } from '../../Icons'
import baseUrl from '../../../config/baseUrl'
import { useSnackbar } from '../../SnackbarProvider'

interface Props {
  open: boolean
  onClose: () => void
}

const ExportDataDialog = ({ open, onClose }: Props) => {
  const userId = sessionStorage.getItem('userId')
  const email = sessionStorage.getItem('email')
  const [isExporting, setIsExporting] = useState(false)
  const showSnackbar = useSnackbar()
  const exportData = async () => {
    setIsExporting(true)
    try {
      const expoertDataResponse = await fetch(`${baseUrl}/api/users/readuserdata`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Email: email,
          userId: userId,
        }),
      })

      const exportData = await expoertDataResponse.json()
      if (expoertDataResponse?.ok) {
        showSnackbar(exportData?.message, 'success')
      } else {
        showSnackbar(exportData?.message, 'error')
      }
    } catch (error: any) {
      showSnackbar('Something went wrong, not able to export data', 'error')
    } finally {
      setIsExporting(false)
    }
  }

  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: 4,
        }}
      >
        {isExporting && (
          <div
            style={{
              color: 'green',
              position: 'absolute',
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Stack spacing={3} width={1}>
          <Stack spacing={2} width={1}>
            <Box display='flex' alignItems='center' gap={2} width={1}>
              <Box display='flex' alignItems='center' color='text.secondary'>
                <UploadIcon sx={{ width: 20, height: 20 }} />
              </Box>
              <Typography variant='body2' fontWeight={600} color='textSecondary'>
                Export Data
              </Typography>
            </Box>
            <Divider component='div' sx={{ borderColor: 'background.border' }} />
          </Stack>
          <Stack spacing={1.3}>
            <Typography variant='body2' color='textSecondary'>
              Are you sure, you want to export data?
            </Typography>
            <List
              // disablePadding
              sx={{
                width: '100%',
                listStyle: 'disc',

                color: 'text.secondary',
                paddingLeft: 2,
              }}
            >
              <li>
                <Typography style={{ width: '100%' }}>
                  Your account details and conversations will be included in the export.
                </Typography>
              </li>
              <li>
                <Typography style={{ width: '100%' }}>
                  The data will be sent to your registered email in a downloadable file.
                </Typography>
              </li>
              <li>
                <Typography style={{ width: '100%' }}>
                  {`Processing may take some time. You'll be notified when it's ready. To proceed, click
                "Export Data" below.`}
                </Typography>
              </li>
            </List>
          </Stack>
          <Stack direction='row' justifyContent='flex-end' spacing={2} width={1}>
            <Button variant='outlined' sx={{ p: 0, maxWidth: 114, height: 44 }} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='contained'
              color='success'
              sx={{ p: 0, maxWidth: 129, height: 44 }}
              onClick={exportData}
            >
              Export Data
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default ExportDataDialog
