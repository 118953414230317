import React, { useState, useEffect } from 'react'

import baseUrl from '../../../config/baseUrl'
import {
  AppBar,
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  // Divider,
  // ListItemText,
  // ListItemButton,
  // List,
  IconButton,
  Slide,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />

})

function index() {
  const [terms, setTerms] = useState('')
  const [privacy, setPrivacy] = useState('')
  const [open, setOpen] = useState(false)
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false)
  const navigate = useNavigate()

  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [selectedButton, setSelectedButton] = useState(null)

  const getTerms = async () => {
    const res = await fetch(`${baseUrl}/api/file/readpdf/2`)
    const data = await res.json()

    setTerms(data)
  }

  const getPrivacy = async () => {
    const res = await fetch(`${baseUrl}/api/fileprivacy/readpdf/1`)
    const data = await res.json()

    setPrivacy(data)
  }

  useEffect(() => {
    document.title = 'Terms & Conditions - LawTech'
    getTerms()
    setOpen(true)
  }, [])

  const handleClickOpen = () => {
    setSelectedButton(1)
    setOpenPrivacyPolicy(true)
    getPrivacy()
  }

  const handleClose = () => {
    setOpen(false)

    navigate('/auth/register')
  }

  const handleClosePrivacy = () => {
    setOpenPrivacyPolicy(false)
    navigate('/auth/privacy-terms')
  }
  const renderFormattedText = (text) => {
    const initialText = text

    return (
      <div>
        <div
          style={{ textAlign: 'justify', whiteSpace: 'pre-line', padding: '20px' }}
          dangerouslySetInnerHTML={{ __html: initialText }}
        />
      </div>
    )
  }

  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', p: 0 }}>
          <Box display='flex' justifyContent='space-between'>
            <Typography sx={{ ml: 2, flex: 1, p: '0.5rem ' }} variant='h6' component='div'>
              Terms, Conditions & Privacy policy
            </Typography>
            <IconButton sx={{ p: 0 }} color='inherit' onClick={handleClose} aria-label='close'>
              <CloseIcon sx={{ color: 'black', mr: 1 }} />
            </IconButton>
          </Box>
        </AppBar>
        <Grid item xs={12} sx={{ overflowY: 'auto', height: '100%' }}>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: 'white',
              marginTop: '1px',
              // width: '100%',
            }}
          >
            <Grid item xs={10} align='right'>
              <Button
                variant='contained'
                className={selectedButton ? '' : ''}
                onClick={handleClickOpen}
                sx={{ py: '0.5rem' }}
              >
                Privacy Policy
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant='contained'
                className={selectedButton ? '' : ''}
                onClick={() => navigate('/auth/register')}
                sx={{ py: '0.5rem' }}
              >
                Back
              </Button>
            </Grid>
          </Grid>

          <hr />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: '20px',
            }}
          >
            <Typography style={{ padding: '20px' }}></Typography>
            <div>{renderFormattedText(terms?.data)}</div>
          </Box>
        </Grid>
      </Dialog>

      {/* ------------------------------ Privacy Policy ----------------------- */}

      <Dialog
        fullScreen
        open={openPrivacyPolicy}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Privacy Policy
            </Typography>

            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClosePrivacy}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </AppBar>
        <Typography sx={{ p: 5, textAlign: 'justify', whiteSpace: 'pre-line' }}>
          {privacy && privacy?.data}
        </Typography>
      </Dialog>
    </>
  )
}

export default index
