import React from 'react'
import {
  Avatar,
  Stack,
  Typography,
} from '@mui/material'



// import Stepper from '../ChatBoat/MainContent/Messages/Actions/Stepper'

interface Props {
  question: any
  index: any
  questionId: any


}


const Question = ({ question }: Props) => {
  return (
    <Stack spacing={1}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' p={2} spacing={3.5}>
        <Stack direction='row' alignItems='center' flexGrow={1} spacing={2}>
          <Avatar variant='square' sx={{ bgcolor: '#587DBD' }}>
            RT
          </Avatar>

          <Typography variant='subtitle2' color='textSecondary'>
            {question}
          </Typography>

        </Stack>
        {/* <Stepper /> */}

      </Stack>

    </Stack>
  )
}

export default Question
