import React, { useEffect, useState } from 'react'
import './index.css'
import { useSidebarContext } from '../../contexts/Old_SidebarContext'
import {
  // Alert,
  // Snackbar,
  AppBar,
  useMediaQuery,
  type Breakpoint,
  Container,
  Toolbar,
  Grid,
  Box,
  IconButton,
} from '@mui/material'
// import type { AlertColor } from '@mui/material/Alert'

// import FactCheckIcon from '@mui/icons-material/FactCheck'
// import { AppLogoIcon } from '../Icons'
import NavLinks from './NavLinks'
import { Link, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import LawTechLogo from '../../assets/images/Law tech logo with patch.png'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  maxWidth: Breakpoint
  hideHeader?: boolean
}

const Header = ({ maxWidth, hideHeader }: Props) => {
  const token = sessionStorage.getItem('token')
  const theme = useTheme()
  // const userId = sessionStorage.getItem('userid')
  // const xsMatched = useMediaQuery(theme.breakpoints.down('xs'))
  const smMatched = useMediaQuery(theme.breakpoints.down('sm'))
  const mdMatched = useMediaQuery(theme.breakpoints.down('md'))
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()

  // Snackbar
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState('');
  // const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>(undefined)

  useEffect(() => {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    }
  }, [location.pathname])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  // const handleCloseSnackbar = () => {
  //   setSnackbarOpen(false);
  // };

  // if (hideHeader) {
  //   return null
  // }

  const containerMaxWidth = smMatched || mdMatched ? false : maxWidth

  return (
    <AppBar style={{ zIndex: 10002 }} position='fixed' color='default' component='nav'>
      <Container
        maxWidth={containerMaxWidth}
        disableGutters={smMatched}
        sx={{ padding: smMatched || mdMatched ? '0.5rem 0rem !important' : '0rem 2.5rem' }}
      >
        <Toolbar
          style={{
            // border: '1px solid black',
            position: 'relative',
          }}
        >
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid item md={6}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                {token === null && (
                  <Link
                    to='/'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <img src={LawTechLogo} alt='' height='75px' />
                    {/* <AppLogoIcon sx={{ width: 91, height: 62 }} / > */}
                  </Link>
                )}

                {token !== null && (
                  <Link
                    to='/NewChat'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <img src={LawTechLogo} alt='' height='75px' />
                    {/* <AppLogoIcon sx={{ width: 91, height: 62 }} / > */}
                  </Link>
                )}

                {/* {token !== null && (
                  <Link to='/verifier'>
                    <FactCheckIcon sx={{ zIndex: 10002, color: 'green', fontSize: 40 }} />
                  </Link>
                )} */}
              </Box>
            </Grid>

            <Grid
              item
              md={6}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
              {/* Toggle button for small screens */}
              {smMatched ? (
                <IconButton onClick={toggleMenu} edge='end' color='inherit' sx={{ mr: 1 }}>
                  {!isMenuOpen ? (
                    <MenuIcon sx={{ fontSize: 35 }} />
                  ) : (
                    <CloseIcon sx={{ fontSize: 35 }} />
                  )}
                </IconButton>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <NavLinks />
                </Box>
              )}
            </Grid>
          </Grid>
        </Toolbar>
        {isMenuOpen && smMatched && (
          <Box sx={{ display: 'block', textAlign: 'center', p: 2 }}>
            <NavLinks />
          </Box>
        )}
      </Container>
    </AppBar>
  )
}

export default Header
