import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AuthContext } from './contexts'
import AppRoutes from './routes'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { SnackbarProvider } from './components/SnackbarProvider'

const App = () => {
  return (
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <AuthContext>
          <CssBaseline />
          <AppRoutes />
        </AuthContext>
      </ThemeProvider>
    </SnackbarProvider>
  )
}
export default App
