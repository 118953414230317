import React, { useEffect, useState } from 'react'
import { IconButton, InputAdornment, Tooltip } from '@mui/material'


interface Props {
  micClick: () => void;
  isListening: boolean;
}
const MicPhoneButton = ({ micClick, isListening }: Props) => {
  // const { listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition()
  const isPaid = sessionStorage.getItem('isPaid')

  const [disableMic, setDisableMic] = useState<boolean>(false)

  useEffect(() => {
    if (!isPaid) {
      setDisableMic(true)
    }
  }, [])

  // const handleMicClick = () => {
  //   if (listening) {
  //     SpeechRecognition.stopListening()
  //     resetTranscript()
  //   } else {
  //     micClick()
  //     SpeechRecognition.startListening()
  //   }
  // }

  return (
    <Tooltip title={'Use Microphone'}>
      <InputAdornment position='end' sx={{ pr: 11 }}>
        <IconButton onClick={micClick}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke={isListening ? 'blue' : 'grey'} // Change color based on state
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='feather feather-mic'
          >
            <path d='M12 1C10.343 1 9 2.343 9 4v8c0 1.657 1.343 3 3 3s3-1.343 3-3V4c0-1.657-1.343-3-3-3zM19 10v2a7 7 0 0 1-14 0v-2m7 10v4m-4 0h8' />
          </svg>
        </IconButton>
      </InputAdornment>
    </Tooltip>
  )
}

export default MicPhoneButton
