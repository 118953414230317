import * as React from 'react'

import ShareChat from './ShareChat'

const SharechatIndex = () => {
  return (
    <ShareChat />
  )
}

export default SharechatIndex
