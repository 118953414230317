import React, { useState, useEffect } from 'react'
import { IconButton, Typography, Button, Box } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import PropTypes from 'prop-types'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    Pagination.propTypes = {
        currentPage: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
    }

    const [inputPage, setInputPage] = useState('')

    useEffect(() => {
        setInputPage(currentPage.toString())
    }, [currentPage])

    const handlePreviousPage = () => {
        onPageChange(currentPage - 1)
    }

    const handleNextPage = () => {
        onPageChange(currentPage + 1)
    }

    const handleInputChange = (e) => {
        const value = e.target.value
        if (value === '' || (Number(value) >= 1 && Number(value) <= totalPages)) {
            setInputPage(value)
        }
    }

    const handleInputSubmit = (e) => {
        e.preventDefault()
        const pageNumber = parseInt(inputPage)
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            onPageChange(pageNumber)
        }
    }

    const handleKeyDown = (e) => {
        // Prevent entering anything other than numbers, backspace, and navigation keys
        if (
            !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight','Enter'].includes(e.key) &&
            (e.key < '0' || e.key > '9')
        ) {
            e.preventDefault()
        }
    }

    // const sidebarWidth = '200px';

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                // position: 'fixed',
                // right: 0,

                // bottom: 5,
                width: '100%',
                // maxWidth: '100vw',
                margin: '1px auto',
            }}
        >
            <Box sx={{ minWidth: '33%', display: { xs: 'none', sm: 'block' } }}>&nbsp;</Box>
            <Box sx={{ minWidth: '33%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IconButton onClick={handlePreviousPage} variant='text' disabled={currentPage === 1}>
                    <ArrowBackIosIcon
                        size='small'
                        style={{ fontSize: '16px', color: 'var(--white-color)' }}
                    />
                </IconButton>

                <Typography style={{ fontSize: '14px' }}>
                    {currentPage}
                    <span style={{ marginLeft: '8px', marginRight: '8px' }}>of</span>
                    {totalPages}
                </Typography>
                <IconButton
                    // size="small"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                // style={{ marginLeft: '10px' }}
                >
                    <ArrowForwardIosIcon
                        size='small'
                        style={{ fontSize: '16px', color: 'var(--white-color)' }}
                    />
                </IconButton>
            </Box>
            <Box sx={{ minWidth: '33%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',mt:{xs:1,sm:0} }}>
                <Typography style={{ fontSize: '14px' }}>{' Go To Page '}</Typography>
                <form onSubmit={handleInputSubmit} style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        // type="number"
                        value={inputPage}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        min={1}
                        max={totalPages}
                        style={{
                            height: '29px',
                            width: '40px',
                            textAlign: 'center',
                            marginRight: '10px',
                            marginLeft: '10px',
                            backgroundColor: '#fefefe',
                            color: 'var(--black-color)',
                            outline: 'none',
                            border: '1px solid grey',
                            borderRadius: '2px',
                        }}
                    />
                </form>
                <Button
                    variant='contained'
                    sx={{
                        // bgcolor: { sendButtonColor },
                        minWidth: 40,
                        width: 40,
                        height: 30,
                        p: 0,
                    }}
                    onClick={handleInputSubmit}
                >
                    Go{' '}
                </Button>
            </Box>
        </Box>
    )
}

export default Pagination
