import React, { useState, useEffect } from 'react'
import {
  Alert,
  Snackbar,
  Avatar,
  Paper,
  Stack,
  IconButton,
  type IconButtonProps,
  styled,
  Tooltip,
  CircularProgress,
  Box,
  useMediaQuery,
} from '@mui/material'
import type { AlertColor } from '@mui/material/Alert'
// import { useMessageContext } from '../../../../contexts'
// import Actions from './Actions'
import { useNavigate, useParams } from 'react-router-dom'
import Message from './Message'
import CopyButton from './Actions/CopyButton'
import LikeButton from './Actions/LikeButton'
import DisLikeButton from './Actions/DisLikeButton'
import EditNoteIcon from '@mui/icons-material/EditNote'

import FactCheckIcon from '@mui/icons-material/FactCheck'
import { useSidebarContext } from '../../../../contexts/Old_SidebarContext'
import { RefreshIcon } from '../../../../components/Icons'
import { useTheme } from '@mui/material/styles'

interface Props {
  question: string
  questionId: string
  answer: string
  historyId: string
  FeedBackstatus: boolean | null
}

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  p: 0,
  width: 24,
  height: 24,
  color: theme.palette.background.border,
  '&:hover': {
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
  },
}))

const Messages = ({ question, questionId, answer, historyId, FeedBackstatus }: Props) => {
  // console.log('question', question)
  const param = useParams()
  const userId: number = sessionStorage.getItem('userId') as unknown as number
  // console.log('param', param)
  // const { messages, activeMessage } = useMessageContext()
  const [goToEditorBtnClicked, setGoToEditorBtnClicked] = useState(false)
  const { answerToLeftDrawer, setAnswerToLeftDrawer } = useSidebarContext()

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>(undefined)

  // Theme
  const theme = useTheme()
  const mdMatched = useMediaQuery(theme.breakpoints.down('md'))

  const [isRegenerating, setIsRegenerating] = useState<boolean>(false)

  const { fetchServerResponse, userStatus, handleSearchCount, setQuestionId, setUserInput } =
    useSidebarContext()
  // console.log('lastAskedQuestion', lastAskedQuestion)
  const navigate = useNavigate()

  useEffect(() => {
    // console.log('answerToLeftDrawer', answerToLeftDrawer)
    if (goToEditorBtnClicked && answerToLeftDrawer !== '') {
      setGoToEditorBtnClicked(false)
      navigate('/editor')
    }
  }, [answerToLeftDrawer])

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handleFactCheck = () => {
    // console.log('handleFactCheck')
    const objectToSend: {
      qs: string
      ans: string
      hId: string
      [key: string]: any
    } = {
      qs: question,
      ans: answer,
      hId: param?.id ?? '',
    }

    let queryString = ''
    for (const key in objectToSend) {
      const value = encodeURIComponent(objectToSend[key])
      queryString += `${key}=${value}&`
    }

    // Remove the trailing ampersand
    queryString = queryString.slice(0, -1)

    const url = '/verifier?' + queryString

    navigate(url)
  }

  const handleEditorClick = () => {
    const objectToSend: {
      qs: string
      ans: string
      hId: string
      [key: string]: any
    } = {
      qs: question,
      ans: answer,
      hId: param?.id ?? '',
    }

    let queryString = ''
    for (const key in objectToSend) {
      const value = encodeURIComponent(objectToSend[key])
      queryString += `${key}=${value}&`
    }

    // Remove the trailing ampersand
    queryString = queryString.slice(0, -1)

    const url = '/editor?' + queryString

    navigate(url)
    // setGoToEditorBtnClicked(true)
    // setAnswerToLeftDrawer(message)
  }

  const handleRegenerate = async () => {
    setUserInput(question)
    setIsRegenerating(true)

    // onUpdate(inputValue)
    if (userId) {
      userStatus(userId)
      const uStatus: any = await userStatus(userId)
      if (uStatus?.status === false) {
        setSnackbarMessage('Something is wrong with user current status, please contact support !')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)
        setIsRegenerating(false)
      } else {
        const promptValue = question ?? ''
        const handleSearchCountResponse = await handleSearchCount(promptValue, userId)
        if (handleSearchCountResponse) {
          const isUpdated = await fetchServerResponse(question)
          if (isUpdated) {
            // console.log('question updated successfully', isUpdated)
            setSnackbarMessage('Answer regenerated successfully')
            setSnackbarSeverity('success')
            setSnackbarOpen(true)
            setIsRegenerating(false)
          }
        } else {
          setSnackbarSeverity('error')
          setIsRegenerating(false)
          setSnackbarOpen(true)
        }
      }
    } else {
      setSnackbarMessage('User credentials are not found, please logout and login again')
      setSnackbarSeverity('warning')
      setSnackbarOpen(true)
    }

    setQuestionId(questionId)
  }

  return (
    <Paper elevation={0} sx={{ p: 2, flexGrow: 1, position: 'relative' }}>
      {isRegenerating && (
        <div
          style={{
            width: '100%',
            position: 'absolute',
            top: '-20px',
            right: '0px',
            zIndex: 1000,
            // backgroundColor: 'rgba(202, 92, 92, 0.5)',
          }}
        >
          <p style={{ color: 'green', textAlign: 'right' }}>Regenerating response ...</p>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress disableShrink color='primary' thickness={4} />
          </Box>
        </div>
      )}
      <Stack spacing={2} direction='row' justifyContent='space-between' alignItems='flex-start'>
        <Avatar variant='square'>LT</Avatar>

        {/* <Actions message={'1'} /> */}
        <Stack direction='row' spacing={2}>
          {/* <Stepper /> */}
          <Tooltip title='Verifier' placement='top'>
            <StyledIconButton aria-label='' onClick={handleFactCheck}>
              <FactCheckIcon />
            </StyledIconButton>
          </Tooltip>

          {!mdMatched && (
            <Tooltip title='Editor' placement='top'>
              <StyledIconButton aria-label='' onClick={handleEditorClick}>
                <EditNoteIcon />
              </StyledIconButton>
            </Tooltip>
          )}

          <CopyButton message={answer} />

          {FeedBackstatus === true ? (
            <LikeButton
              historyId={historyId}
              answer={answer}
              alreadyLiked={true}
              alreadyDisliked={false}
            />
          ) : FeedBackstatus === false ? (
            <DisLikeButton
              historyId={historyId}
              answer={answer}
              alreadyLiked={false}
              alreadyDisliked={true}
            />
          ) : (
            <>
              <LikeButton
                historyId={historyId}
                answer={answer}
                alreadyLiked={false}
                alreadyDisliked={false}
              />
              <DisLikeButton
                historyId={historyId}
                answer={answer}
                alreadyLiked={false}
                alreadyDisliked={false}
              />
            </>
          )}

          <Tooltip title='Regenerate' placement='top'>
            <StyledIconButton aria-label='' onClick={handleRegenerate}>
              <RefreshIcon sx={{ fontWeight: 'bold' }} />
            </StyledIconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Message message={answer} />

      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert elevation={6} onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </Paper>
  )
}

export default Messages
