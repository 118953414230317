import React, { useState } from 'react'
import { Link as RouteLink } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  alpha,
  Typography,
  Link,
  Stack,
  FormGroup,
  FormControlLabel,
  useTheme,
  CircularProgress
} from '@mui/material'

import { BankNoteIcon, LandmarkIcon, ScaleIcon } from '../../../components/Icons'
import baseUrl from '../../../config/baseUrl'

interface Props {
  setDialog: any
}

const Categories = ({ setDialog }: Props) => {
  const userId = sessionStorage.getItem('userId')
  const { palette } = useTheme()
  // const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [civil, setCivil] = useState(false)
  const [criminal, setCriminal] = useState(false)
  const [bankruptcy, setBankruptcy] = useState(false)
  const [loading, setLoading] = useState(false)

  const categoryBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    maxHeight: 238,
    minHeight: { xs: 180, sm: '238' },
    height: '100%',
    maxWidth: { xs: 150, sm: 194 },
    minWidth: { xs: 150, sm: 170 },
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: civil || criminal || bankruptcy ? palette.primary.main : 'rgba(0, 0, 0, 0.2)',
    borderRadius: 1,
    position: 'relative',
    cursor: 'pointer',
    gap: 3,
  }

  // const handleClick = (selectedValue: string) => {
  //   if (selectedCategory !== selectedValue) {
  //     setSelectedCategory(selectedValue)
  //   } else {
  //     setSelectedCategory('')
  //   }
  // }

  const dataSubmit = async () => {
    setLoading(true)
    if (userId) {
      try {
        // Make an API call here using the formData
        const response = await fetch(`${baseUrl}/api/auths/registerstep2`, {
          method: 'POST',
          headers: {
            Origin: `${baseUrl}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userid: userId,
            Category: civil ? 'Civil Cases' : '',
            Category2: criminal ? 'Criminal Cases' : '',
            Category3: bankruptcy ? 'Bankruptcy Cases' : '',
          }),
        })

        const data = await response.json()

        if (data.status === true) {
          // Handle successful response
          setDialog(true)
        }
      } catch (error) {
        console.error('An error occurred while submitting the form data', error)
      }finally{
        setLoading(false)
      }
    }
  }

  return (
    <>
      <Box sx={{ width: '100%', mt: 5 }}>
        <FormGroup>
          <Stack
            direction='row'
            spacing={{ xs: 1, sm: 2, md: 4 }}
            useFlexGap
            flexWrap='wrap'
            sx={{
              alignItems: { xs: 'center', md: 'flex-start' },
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Box
              sx={{ ...categoryBoxStyle, bgcolor: civil ? alpha(palette.primary.main, 0.1) : null }}
            >
              <LandmarkIcon
                sx={{
                  fill: 'none',
                  stroke: civil ? palette.text.secondary : palette.text.primary,
                  bgcolor: civil ? alpha(palette.primary.main, 0.1) : null,
                }}
              />
              <FormControlLabel
                sx={{ margin: '0px !important' }}
                control={
                  <Checkbox
                    checked={civil}
                    onClick={() => {
                      setCivil(!civil)
                    }}
                  />
                }
                label='Civil Cases'
              />
            </Box>

            <Box
              sx={{
                ...categoryBoxStyle,
                bgcolor: criminal ? alpha(palette.primary.main, 0.1) : null,
              }}
            >
              <ScaleIcon
                sx={{
                  fill: 'none',
                  stroke: criminal ? palette.text.secondary : palette.text.primary,
                  bgcolor: criminal ? alpha(palette.primary.main, 0.1) : null,
                }}
              />
              <FormControlLabel
                sx={{ margin: '0px !important' }}
                control={
                  <Checkbox
                    checked={criminal}
                    onChange={() => {
                      setCriminal(!criminal)
                    }}
                  />
                }
                label='Criminal Cases'
              />
            </Box>

            <Box
              sx={{
                ...categoryBoxStyle,
                bgcolor: bankruptcy ? alpha(palette.primary.main, 0.1) : null,
              }}
            >
              <BankNoteIcon
                sx={{
                  width: 35,
                  height: 'auto',
                  fill: 'none',
                  stroke: bankruptcy ? palette.text.secondary : palette.text.primary,
                }}
              />
              <FormControlLabel
                sx={{ margin: '0px !important' }}
                control={
                  <Checkbox
                    checked={bankruptcy}
                    onChange={() => {
                      setBankruptcy(!bankruptcy)
                    }}
                  />
                }
                label='Bankruptcy Cases'
              />
            </Box>
          </Stack>
        </FormGroup>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex- start' },
          width: '100%',
          gap: 2.5,
        }}
      >
                    <Box display='flex' sx={{width: '100%',position: 'relative', justifyContent: 'center', alignItems: 'center',}}>
        <Button
          disabled={!civil && !criminal && !bankruptcy}
          variant='contained'
          onClick={() => {
            dataSubmit()
          }}
          sx={{mt:2}}
        >
          Sign Up
        </Button>
        {loading && <CircularProgress  sx={{ ml: 1,mt:2,color: '#353858',position: 'absolute',left: 'calc(50% + 80px)', }} />}
            </Box>
        {/* <Typography variant='subtitle1'>
          Already a member?
          <Link
            component={RouteLink}
            variant='subtitle2'
            to='/auth/login'
            underline='hover'
            color='textSecondary'
            ml={0.5}
          >
            Login
          </Link>
        </Typography> */}
      </Box>
    </>
  )
}

export default Categories
