import React from 'react'
import { Stack, Typography, useTheme, useMediaQuery } from '@mui/material'
// import { MessageSquareIcon } from '../../../../components/Icons'
import Actions from './Actions'
import { useNavigate } from 'react-router-dom'
import { useSidebarContext } from '../../../../contexts/Old_SidebarContext'

interface Props {
  selectedQuestionId: number
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<number>>
  title: any
  historyId: any
  itemId: number
  threadId: any
}

const HistoryItem = ({
  title,
  historyId,
  itemId,
  selectedQuestionId,
  setSelectedQuestionId,
  threadId,
}: Props) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { setLastAskedQuestion, setInputMessage, setSidebar, setHistoryClicked, newChatClicked, setNewChatClicked } = useSidebarContext()



  const redirectToChat = (id: number) => {
    itemId && setSelectedQuestionId((prevId) => (prevId = itemId))
    setHistoryClicked(true)
    // console.log('histiryID', id)
    // setThreadId(id)
    if (isMobile) {
      setSidebar(false)
    }
    navigate(`/NewChat/${id}`)
  }

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      px={1.5}
      py='10px'
      borderBottom='0.25px solid'
      borderColor='common.gray2'
    >
      <Stack direction='row' alignItems='center'>
        {/* <Stack alignItems='center' color='common.gray' mr={1.5}>
          <MessageSquareIcon />
        </Stack> */}
        <Stack
          sx={{
            cursor: 'pointer',
            color: '#777',
            bgcolor: '#fcfcfc)',
            ...(selectedQuestionId === itemId && !newChatClicked && {
              bgcolor: '#587DBD',
              color: '#f4f4f4',
            }),
            p: 1,
            boxShadow: 3,
            '&:hover': {
              // border: '1px solid #f3f307',
              color: 'grey',
              backgroundColor: '#f7f7f7',
            },
            borderRadius: '5px',
          }}
          direction='row'
          onClick={() => {
            setLastAskedQuestion(title)
            setNewChatClicked(false)
            redirectToChat(threadId)
            setInputMessage('')
          }}
          width={200}
          justifyContent='flex-start'
          position='relative'
        >
          {/* <Box
            sx={{
              width: 21,
              height: 21,
              position: 'absolute',
              background: 'linear-gradient(270deg, #FFF 33.33%, rgba(255, 255, 255, 0.00) 100%)',
            }}
          /> */}
          <Typography variant='subtitle2' noWrap>
            {title}
          </Typography>
        </Stack>
      </Stack>
      <Actions historyId={historyId} threadId={threadId} title={title} />
    </Stack>
  )
}

export default HistoryItem
