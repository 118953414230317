import React from 'react'
// import { Outlet } from 'react-router-dom'
import ChatBoat from '../pages/ChatBoat'
// import LeftSidebar from '../pages/ChatBoat/LeftSidebar'

import { FilteredProvider } from '../contexts'

function SideBarLayout() {
  return (
    <div>
      <FilteredProvider>
        <ChatBoat />
      </FilteredProvider>
    </div>
  )
}

export default SideBarLayout
