import React, { useState } from 'react'
import { type IconButtonProps, styled, IconButton } from '@mui/material'
import { ThumbsUpIcon } from '../../../../../components/Icons'
import { LikeFeedbackDialog } from '../../../../../components/shared/Dialogs'
import { useSnackbar } from '../../../../../components/SnackbarProvider'
const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  p: 0,
  width: 24,
  height: 24,
  color: theme.palette.background.border,
  '&:hover': {
    color: theme.palette.success.main,
    backgroundColor: 'transparent',
  },
}))

interface Props {
  historyId: string
  answer: string
  alreadyLiked: boolean
  alreadyDisliked: boolean
}

const LikeButton = ({ historyId, answer, alreadyLiked, alreadyDisliked }: Props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const showSnackbar = useSnackbar()
  const handleClick = () => {
    if (alreadyLiked) {
      showSnackbar('You already liked this question.','info')
      return
    }
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }
  return (
    <>
      <StyledIconButton onClick={handleClick}>
        <ThumbsUpIcon alreadyLiked={alreadyLiked} alreadyDisliked={false} strokeColor='#05e166' />
      </StyledIconButton>
      <LikeFeedbackDialog
        open={openDialog}
        onClose={handleClose}
        historyId={historyId}
        answer={answer}
      />
    </>
  )
}

export default LikeButton
