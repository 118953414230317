import React, { useEffect, useState } from 'react'
import { Box, Paper, Stack, Typography, Button } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player'
// import { AppLogoIcon } from '../../../components/Icons'
import { useParams } from 'react-router-dom'
import baseUrl from '../../../config/baseUrl'
import { useSnackbar } from '../../../components/SnackbarProvider'
import SquareLogo from '../../../assets/images/Lawtech icon square.png'

const ResetPassword = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
const showSnackbar = useSnackbar()
  const { email } = useParams()

  useEffect(() => {
    document.title = 'Reset password - LawTech'
  }, [])


  useEffect(() => {
    if (isButtonDisabled) {
      setTimeout(() => {
        setIsButtonDisabled(false) // Enable the button after 3 minutes if it's still disabled
      }, 3 * 60 * 1000) // 3 minutes in milliseconds
    }
  }, [isButtonDisabled])


  const forgetPassword = async () => {
    try {
      // Set loading state while the request is being processed.

      const forgetPasswordResponse = await fetch(`${baseUrl}/api/auths/forgotpass`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Email: email,
        }),
      })

      const forgetPasswordResponseData = await forgetPasswordResponse.json()

      if (!forgetPasswordResponse.ok) {
        showSnackbar(forgetPasswordResponseData.message,'error')
      }

      if (forgetPasswordResponse.ok) {
        showSnackbar(forgetPasswordResponseData.message,'success')
      }
    } catch (error: any) {
      // console.log('Error in Forget Password : ', error.message)
      showSnackbar(error.message,'error')
    }
  }


  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        overflow: 'hidden',
        bgcolor: 'common.white',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 1,
          maxWidth: 524,
          minHeight: 562,
          borderRadius: 1,
          p: 1.5,
        }}
      >
        <Stack spacing={2}>
          <Stack alignItems='center' mt={4} spacing={2.5}>
            {/* <AppLogoIcon sx={{ width: 91, height: 62 }} /> */}
            <img src={SquareLogo} alt='' height='60px' />
          </Stack>
          <Player
            autoplay
            loop
            src='https://lottie.host/998a3e96-c186-4c72-8a5b-7939c3a7a45f/GjPnUluhZ8.json'
            style={{ height: 120, width: 120 }}
          />
          <Stack alignItems='center'>
            <Stack spacing={2.5} mb={3.3} maxWidth={312}>
              <Typography variant='h5' align='center'>
                Check your Email
              </Typography>
              <Typography variant='body1' align='center'>
                Please check your email <strong style={{ fontWeight: '700' }}>{email}</strong> for
                instructions to reset your password.
              </Typography>
            </Stack>
            <Button
              variant='contained'
              sx={{ maxWidth: 160, marginTop: '30px' }}
              onClick={forgetPassword}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
        <Stack></Stack>
      </Paper>
    </Box>
  )
}

export default ResetPassword
