import React from 'react'
import baseUrl from '../../../../config/baseUrl'
import { Box, Button, Tooltip, IconButton, Stack, Typography } from '@mui/material'
import { useSnackbar } from '../../../../components/SnackbarProvider'
import { CheckIcon } from '../../../../components/Icons'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import InfoIcon from '@mui/icons-material/Info'

const Plans = (Props) => {
  const { plan, subscribedPlanId, subscribedBasicPlan, planExpiryDate } = Props
  const givenDate = new Date(planExpiryDate)
  const currentDate = new Date()
  // const [showMessage, setShowMessage] = React.useState(false)
  const showSnackbar = useSnackbar()
  // console.log('plan, subscribedPlanId, onClose', plan, subscribedPlanId, onClose)

  //   const email = sessionStorage.getItem('email')

  const userId = sessionStorage.getItem('userId')

  // const subscriptionid = sessionStorage.getItem('subscriptionid')

  //   const phone = sessionStorage.getItem('phone')

  //   const firstName = sessionStorage.getItem('firstName')

  //   const lastName = sessionStorage.getItem('lastName')

  //   const fullName = `${firstName} ${lastName}`

  const token = sessionStorage?.getItem('token')

  // const isPaid = sessionStorage?.getItem('isPaid')

  // console.log('Plans, isPaid', isPaid)

  const handleUpgradePlan = async (plan) => {
    // console.log('plan', plan)
    try {
      const subscriptionResonse = await fetch(`${baseUrl}/api/order/create`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token,
        },
        body: JSON.stringify({
          userid: userId,
          amount: plan?.Price,
          subscriptionid: plan?.id,
        }),
      })

      // console.log('subscriptionResonse', subscriptionResonse)

      const subscriptionResonseData = await subscriptionResonse.json()
      if (subscriptionResonseData.status === false) {
        // console.log('false basic');
        showSnackbar(subscriptionResonseData.message, 'error')
        return
      }
      // console.log('subscriptionResonseData', subscriptionResonseData)
      if (subscriptionResonse.ok) {
        // window.open(subscriptionResonseData.payment_link, '_blank')
        window.open(subscriptionResonseData.payment_link, '_self')
      }
    } catch (error) {
      showSnackbar(`Error in upgrade subscription :${error?.message}`, 'error')
    }
    // onClose()
  }

  return (
    <Box
      position='relative'
      sx={{ minWidth: { xs: 'calc(25%)', sm: 'calc(35%)' }, maxWidth: { xs: '100%', sm: 'auto' } }}
      border='0.5px solid'
      borderColor='primary.main'
      borderRadius={1}
      boxShadow='0px 0px 12px rgba(0, 0, 0, 0.08)'
      bgcolor='common.white'
      p={1.5}
      spacing={2.5}
      zIndex={1}
    >
      <Box>
        <Stack spacing={1}>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography component='h6' variant='bold' color='textSecondary'>
              {plan?.PlanName}
            </Typography>
            {/* {plan?.PlanName !== 'Basic' && ( */}
            <Button
              onClick={() => {
                handleUpgradePlan(plan)
              }}
              variant='contained'
              sx={{ maxWidth: 146, height: 40, p: 0, display: { xs: 'none', sm: 'block' } }}
              disabled={
                (subscribedPlanId === plan?.id && givenDate > currentDate) ||
                (!subscribedPlanId && plan?.PlanName === 'Top-up') ||
                (subscribedBasicPlan && plan?.PlanName === 'Basic')
              }
            >
              {subscribedPlanId === plan?.id && givenDate > currentDate
                ? 'Subscribed'
                : 'Upgrade Plan'}
              {/* {subscribedPlanId === 1 && plan?.name === 'Top-up' ? 'Subscribed' : 'Upgrade Plan'} */}
            </Button>
            {/* )} */}

            {/* <VisibilityBlackIcon sx={{ width: 20, height: 20, color: 'success.main' }} /> */}
          </Box>
          {/* <Button variant='contained' disabled sx={{ maxWidth: 146, height: 40, p: 0 }}>
          Current Plan
        </Button> */}
        </Stack>
        <Stack spacing={1} zIndex={3}>
          <Box display='flex' alignItems='flex-start'>
            <CheckIcon sx={{ width: 20, height: 20, mr: 1.5, color: 'primary.main' }} />
            <Typography variant='subtitle1'>
              Price : <span>&#8377;</span> {plan?.Price?.toLocaleString('en-US')}
            </Typography>
          </Box>

          {/* <Box display='flex' alignItems='flex-start'>
          <CheckIcon sx={{ width: 20, height: 20, mr: 1.5, color: 'primary.main' }} />
          <Typography variant='subtitle1'>Features : {plan?.Features}</Typography>
        </Box> */}
          {/* <Box display='flex' alignItems='flex-start'>
          <CheckIcon sx={{ width: 20, height: 20, mr: 1.5, color: 'primary.main' }} />
          <Typography variant='subtitle1'>
            Tokens : {plan?.Totaltoken?.toLocaleString('en-US')}
          </Typography>
        </Box> */}
          <Box display='flex' alignItems='flex-start' zIndex={4} position='relative'>
            <CheckIcon sx={{ width: 20, height: 20, mr: 1.5, color: 'primary.main' }} />
            <Typography variant='subtitle1'>
              Words (approx) : {Math.ceil((plan?.Totaltoken * 75) / 100).toLocaleString('en-US')}
            </Typography>

            <Tooltip
              disableFocusListener
              title={`${plan?.Totaltoken} tokens is equal to ${Math.ceil(
                (plan?.Totaltoken * 75) / 100,
              )} words`}
              placement='right'
              sx={{ zIndex: '10002 !important' }}
            >
              <IconButton sx={{ ml: '0.5rem', mt: '-10px' }}>
                <InfoIcon sx={{ color: '#777' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display='flex' sx={{ justifyContent: { xs: 'space-between', sm: 'flex-end' } }}>
            <Button
              onClick={() => {
                handleUpgradePlan(plan)
              }}
              variant='contained'
              sx={{ maxWidth: 146, height: 40, p: 0, display: { xs: 'block', sm: 'none' } }}
              disabled={
                (subscribedPlanId === plan?.id && givenDate > currentDate) ||
                (!subscribedPlanId && plan?.PlanName === 'Top-up') ||
                (subscribedBasicPlan && plan?.PlanName === 'Basic')
              }
            >
              {subscribedPlanId === plan?.id && givenDate > currentDate
                ? 'Subscribed'
                : 'Upgrade Plan'}
              {/* {subscribedPlanId === 1 && plan?.name === 'Top-up' ? 'Subscribed' : 'Upgrade Plan'} */}
            </Button>
            {subscribedPlanId === plan?.id && givenDate > currentDate && (
              <CheckBoxIcon color='success' fontSize='large' />
            )}
          </Box>
          {plan?.PlanName === 'Top-up' && (
            <Typography variant='body2' fontSize='12px' color='red' component='p' align='right'>
              Top-up plan is only for <strong>subscribed</strong> users
            </Typography>
          )}
          {/* {plan?.PlanName === 'Basic' && (
          <Typography variant='body1' fontSize='12px' color='green' component='p' align='right'>
            <strong>Free Plan</strong>
          </Typography>
        )} */}
        </Stack>
      </Box>
    </Box>
  )
}

export default Plans
