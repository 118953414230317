import React, { useState, useEffect } from 'react'
import { Box, useTheme, useMediaQuery } from '@mui/material'
import Vimeo from '@u-wave/react-vimeo'

const ImageComponent = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [mobileWidth, setMobileWidth] = useState(window.innerWidth - 20);

  useEffect(() => {
    const handleResize = () => {
      setMobileWidth(window.innerWidth - 20);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const mobileHeight = (mobileWidth * 9) / 16;
  // return <Image src={src} alt={alt} />
  const videoId =
    'https://player.vimeo.com/video/941186530?h=7a57828abe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        minWidth: '100%',
      }}
    >
      <Vimeo
        style={{ boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.25)' }}
        video={videoId}
        // autoplay={true}
        muted={true}
        width={isMobile ? mobileWidth : 426}
        height={isMobile ? mobileHeight : 240}
      />
    </Box>
  )
}

export default ImageComponent
