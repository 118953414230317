import React from 'react'
import { FormControl, InputLabel, FormHelperText, InputBase } from '@mui/material'

interface Props {
  type?: string
  name: string
  label?: string
  size?: string
  value: string
  rows?: number
  placeholder?: string
  error?: string
  isRequired?: boolean
  multiline?: boolean
  disabled?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  textarea?: {
    multiline: boolean
    maxRows?: number
    minRows?: number
  }
  endAdornment?: React.ReactElement
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onCopy?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onPaste?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TextField = ({
  label,
  type,
  name,
  size,
  value,
  placeholder,
  error,
  isRequired,
  textarea,
  rows,
  multiline,
  disabled,
  inputRef,
  endAdornment,
  onChange,
  onCopy,
  onPaste,
}: Props) => {
  const hasError = !!error
  return (
    <FormControl fullWidth error={hasError} disabled={disabled}>
      <InputLabel htmlFor={name} shrink={true} required={isRequired}>
        {label}
      </InputLabel>
      <InputBase
        autoComplete='off'
        id={name}
        name={name}
        type={type}
        rows={rows}
        multiline={multiline}
        value={value}
        inputRef={inputRef}
        {...textarea}
        placeholder={placeholder}
        onChange={onChange}
        endAdornment={endAdornment}
        sx={{
          ...(size === 'small' && {
            height: '40px', // Adjust form control height for small
          }),
          ...(size === 'medium' && {
            height: '60px', // Adjust form control height for small
          }),
        }}
      />
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default TextField
