import React, { useState } from 'react'
import { IconButton, Stack, Tooltip } from '@mui/material'
import { EditIcon, LinkIcon, TrashIcon } from '../../../../components/Icons'
import {
  DeleteConfirmationDialog,
  EditChatTitleDialog,
} from '../../../../components/shared/Dialogs'
import { useCopyToClipboard } from '../../../../hooks'
import { useSnackbar } from '../../../../components/SnackbarProvider'
import baseUrl, { domain } from '../../../../config/baseUrl'

interface Props {
  historyId: any
  threadId: any
  title: any
}

const Actions = ({ historyId, threadId, title }: Props) => {
  const [openDialog, setOpenDialog] = useState<{ editTitleDialog: boolean; deleteDialog: boolean }>(
    {
      editTitleDialog: false,
      deleteDialog: false,
    },
  )

  const userId = sessionStorage.getItem('userId')
  const showSnackbar = useSnackbar()
  const handleClick = (name: string) => (_event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [name]: true,
    }))
  }

  const handleClose = (name: string) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [name]: false,
    }))
  }

  const [copyToClipboard] = useCopyToClipboard()
  const url = `${domain}/ShareChat/${threadId as string}`
  const handleShareClick = async () => {
    copyToClipboard(url)

    return false
  }

  const shareLink = async () => {
    try {
      const shareLinkRepsonse = await fetch(`${baseUrl}/api/sharechat/storesharechatnew`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          UserId: userId,
          ThreadId: threadId,
        }),
      })

      const shareLinkResponseData = await shareLinkRepsonse?.json()

      if (shareLinkRepsonse.ok) {
        showSnackbar(shareLinkResponseData?.message, 'success')
        sessionStorage.setItem('linkId', shareLinkResponseData?.data?.id)
      } else {
        showSnackbar(shareLinkResponseData?.message, 'error')
      }
    } catch (error: any) {
      // console.log('Error in shareLink api : ', error.message)
      showSnackbar(error?.message, 'error')
    }
  }

  return (
    <>
      <Stack direction='row' spacing={1}>
        <Tooltip title='Edit' placement='top'>
          <IconButton sx={{ p: 0, color: 'common.gray' }} onClick={handleClick('editTitleDialog')}>
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title='Share' placement='top'>
          <IconButton
            sx={{ p: 0, color: 'common.gray' }}
            onClick={() => {
              shareLink()
              handleShareClick()
            }}
          >
            <LinkIcon strokeWidth='1.5' />
          </IconButton>
        </Tooltip>

        <Tooltip title='Delete' placement='top'>
          <IconButton sx={{ p: 0, color: 'common.gray' }} onClick={handleClick('deleteDialog')}>
            <TrashIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <EditChatTitleDialog
        open={openDialog?.editTitleDialog}
        onClose={() => {
          handleClose('editTitleDialog')
        }}
        historyId={historyId}
        title={title}
      />
      <DeleteConfirmationDialog
        open={openDialog?.deleteDialog}
        title='Are you sure, you want to Delete this Chat ?'
        onConfirm={() => {
          handleClose('deleteDialog')
        }}
        onClose={() => {
          handleClose('deleteDialog')
        }}
        threadIdToDelete={threadId}
      />
    </>
  )
}

export default Actions
