import React from 'react'
import { Box } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

interface Props {
  message: any
}



const Message = ({ message }: Props) => {



  const MarkdownRenderer = (props: any) => {
    /* eslint-disable react/prop-types */
    const { textToDisplay } = props
    // console.log('355 textToDisplay', textToDisplay)
    // console.log('message', message)

    return (
      <Box
        sx={{
          minHeight: '100%',
          width: '100%',
          overflow: 'auto',
          textAlign: 'justify',
          padding: {
            xs: '10px 0px',
            sm: '10px',
          },
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{textToDisplay}</ReactMarkdown>
      </Box>
    )
  }

  // return <div>{renderFormattedText(message)}</div>
  return <MarkdownRenderer textToDisplay={message} />
}

export default Message
