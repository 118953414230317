import React, { type ChangeEvent, useState, useEffect } from 'react'
import {
  Alert,
  Snackbar,
  Avatar,
  Button,
  IconButton,
  InputBase,
  type InputBaseProps,
  Stack,
  Typography,
  styled,
  alpha,
  CircularProgress,
  Box,
} from '@mui/material'
import type { AlertColor } from '@mui/material/Alert'

import LoadingButton from '@mui/lab/LoadingButton'
import { EditIcon } from '../../../../components/Icons'
import { useSidebarContext } from '../../../../contexts/Old_SidebarContext'
// import Stepper from '../Messages/Actions/Stepper'

interface Props {
  question: any
  index: any
  questionId: any
}

const StyledInputBase = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  borderWidth: 0.5,
  flexGrow: 1,
  height: 41,
  borderColor: alpha(theme.palette.common.black, 0.2),
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1.5),
  },
}))

const Question = ({ question, questionId, index }: Props) => {
  // console.log('question', question, questionId)
  const userId: number = sessionStorage.getItem('userId') as unknown as number
  const [isEdit, setEdit] = useState<boolean>(false)
  const [questionIdBeingEdited, setQuestionIdBeingEdited] = useState<string | null>(null)
  const [inputValue, setInputValue] = useState<string>(question)
  // const isPaid = sessionStorage.getItem('isPaid')

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>(undefined)

  const {
    isQuestionUpdating,
    setIsQuestionUpdating,
    fetchServerResponse,
    userStatus,
    handleSearchCount,
    setIsLoading,
    setQuestionId,
    setUserInput,
  } = useSidebarContext()

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    isEdit && setInputValue(question)
  }, [isEdit])

  useEffect(() => {
    if (question) {
      setInputValue(question)
    }
  }, [question])

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    setUserInput(event.target.value) // Update the message state
  }

  const handleUpdate = async () => {
    setIsQuestionUpdating(true)
    setEdit(false)
    // onUpdate(inputValue)
    if (userId) {
      userStatus(userId)
      const uStatus: any = await userStatus(userId)
      if (uStatus?.status === false) {
        setSnackbarMessage('Something is wrong with user current status, please contact support !')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)
        setIsQuestionUpdating(false)
      } else {
        const promptValue = inputValue ?? ''
        const handleSearchCountResponse = await handleSearchCount(promptValue, userId)
        if (handleSearchCountResponse) {
          const isUpdated = await fetchServerResponse(inputValue)
          if (isUpdated) {
            // console.log('question updated successfully', isUpdated)
            setSnackbarMessage('Question updated successfully')
            setSnackbarSeverity('success')
            setSnackbarOpen(true)
            setIsQuestionUpdating(false)
            setQuestionIdBeingEdited(null)
          }
        } else {
          setSnackbarSeverity('error')
          setIsQuestionUpdating(false)
          setSnackbarOpen(true)
        }
      }
    } else {
      setSnackbarMessage('User credentials are not found, please logout and login again')
      setSnackbarSeverity('warning')
      setSnackbarOpen(true)
    }

    setQuestionId(questionId)
  }

  const toggleClick = (qId: any): ((event: React.MouseEvent<HTMLElement>) => void) => {
    return (event: React.MouseEvent<HTMLElement>) => {
      setEdit(!isEdit)
      setQuestionIdBeingEdited(qId)
    }
  }

  // const token = sessionStorage.getItem('token')

  return (
    <div style={{ position: 'relative' }}>
      {isQuestionUpdating && questionIdBeingEdited === questionId && (
        <div
          style={{
            width: '100%',
            position: 'absolute',
            top: '-20px',
            right: '0px',
            zIndex: 1000,
            // backgroundColor: 'rgba(202, 92, 92, 0.5)',
          }}
        >
          <p style={{ color: 'green', textAlign: 'right' }}>
            Creating a response based on your edited prompt...
          </p>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress disableShrink color='primary' thickness={4} />
          </Box>
        </div>
      )}
      <Stack spacing={1}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            pt: 1,
            pb: 0,
            pl: 2,
            pr: 2,
          }}
          spacing={1.5}
        >
          <Stack direction='row' alignItems='center' flexGrow={1} spacing={2}>
            <Avatar variant='square' sx={{ bgcolor: '#587DBD' }}>
              RT
            </Avatar>
            {!isEdit && (
              <Typography variant='subtitle2' color='textSecondary'>
                {question}
              </Typography>
            )}
            {isEdit && <StyledInputBase value={inputValue} onChange={handleChange} />}
          </Stack>
          {/* <Stepper /> */}
          <IconButton sx={{ p: 0.5, color: 'background.border' }} onClick={toggleClick(questionId)}>
            <EditIcon />
          </IconButton>
        </Stack>
        {isEdit && (
          <Stack direction='row' spacing={3} justifyContent='flex-end'>
            <Button
              variant='outlined'
              sx={{ p: 0, width: 94, height: 40 }}
              onClick={toggleClick(questionId)}
            >
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              sx={{ p: 0, width: 96, height: 40 }}
              onClick={() => {
                handleUpdate()
              }}
            >
              Update
            </LoadingButton>
          </Stack>
        )}
      </Stack>
      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          sx={{ zIndex: '15000' }}
        >
          <Alert elevation={6} onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  )
}

export default Question
