import React, { useState, useEffect } from 'react'

import { Stack, styled, Typography, Button, Box } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { useSidebarContext } from '../../contexts/Old_SidebarContext'

import { DRAWER_WIDTH } from '../ChatBoat/constants'
import Stepper from './ShareChatStepper'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: any
}>(({ theme, open }) => ({
  display: 'flex',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  padding: theme.spacing(2, 4, 2, 2),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: theme.spacing(2),
  }),
}))

const ShareChat = () => {
  const { allHistory, setSidebar, setThreadId } = useSidebarContext()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const param = useParams()
  const { id } = param

  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Shared chat - LawTech'
  }, [])

  // Rest of your component code...
  useEffect(() => {
    id && setThreadId(id)
    setIsOpen(false)
    setSidebar(false)
  }, [id])

  // Updated code for pagination 3

  const [mergedData, setMergedData] = useState<any>([])

  useEffect(() => {
    const newArray = allHistory.map((obj: any) => {
      if (obj.subSerach) {
        return {
          ...obj,
          subSerach: [obj, ...obj.subSerach], // Move the object into subSerach
        }
      } else {
        return obj
      }
    })

    setMergedData(newArray)
  }, [id, allHistory])

  const [subSearchActiveSteps, setSubSearchActiveSteps] = useState<any>(allHistory.map(() => 0))

  useEffect(() => {
    setSubSearchActiveSteps(allHistory.map(() => 0))
  }, [allHistory])

  const handleSubSearchStepChange = (index: number, step: number) => {
    // alert(index)

    setSubSearchActiveSteps((prevActiveSteps: any) => {
      const newActiveSteps = [...prevActiveSteps]

      newActiveSteps[index] = step

      return newActiveSteps
    })
  }

  const handleSignIn = () => {
    navigate('/auth/register')
  }

  return (
    <>
      <Main open={isOpen}>
        <Stack flexGrow={1} alignItems='center' position='relative'>
          <Stack flexGrow={1} spacing={2} width={1} maxWidth={1000}>
            <Stack flexGrow={1} spacing={2}>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography>
                  These results are produced by LawTech. Feel free to try more by signing in...
                </Typography>
                <Button variant='contained' onClick={handleSignIn}>
                  Sign In
                </Button>
              </Box>
              {mergedData?.map((each: any, eachIndex: number) => (
                <div key={each.id}>
                  <Stepper
                    subSearchArray={each.subSerach || []}
                    activeStep={subSearchActiveSteps[eachIndex]}
                    handleStepChange={(step: number) => {
                      handleSubSearchStepChange(eachIndex, step)
                    }}
                  />

                  {/* Render additional content inside <div> if needed */}
                  <div>
                    {/* Your content goes here */}
                    {/* For example, assuming there's a property named 'content', you can do: */}
                    <div
                      style={{ textAlign: 'justify', color: 'red', padding: '20px' }}
                      dangerouslySetInnerHTML={{ __html: each.content }}
                    />
                  </div>
                </div>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Main>
    </>
  )
}

export default ShareChat
