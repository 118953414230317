import React from 'react'
import { Box, Stack } from '@mui/material'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import LeftSidebar from './LeftSidebar'
import { SidebarProvider } from '../../contexts'

// import { HistoryProvider } from '../../contexts/HistoryContext'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Outlet, useLocation } from 'react-router-dom'

const ChatBoat = () => {
  // const userId = sessionStorage.getItem('userId')

  // const token = sessionStorage.getItem('token')
  const location = useLocation()

  const isShareChatPath = location.pathname.includes('/ShareChat')

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // mt: 12,

          bgcolor: 'common.white',
        }}
      >
        {/* <Header maxWidth='false' /> */}
        {isShareChatPath ? null : <Header maxWidth='false' />}
        <Stack>
          <Stack direction='row' flexGrow={1} bgcolor='background.dark'>
            <SidebarProvider>
              <LeftSidebar />
              <Outlet />
            </SidebarProvider>
          </Stack>
        </Stack>
      </Box>

      <Footer small />
    </>
  )
}

export default ChatBoat
