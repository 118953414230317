import * as React from 'react'
import { Box, Button, Stack } from '@mui/material'
import offerImage from '../../assets/images/offerImage.jpeg'
// import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ContactSection from './ContactUs'
import WhyChooseUsSection from './WhyChooseUs'
// import OurClientsSection from './OurClients'
import HowItWorksSection from './HowItWorks'
import MainSection from './MainSection'

const HomeIndex = () => {
  // console.log('home Index')
  const currentDate = new Date()
  const targetDate = new Date('2024-12-31')
  const [isVisible, setIsVisible] = React.useState(false)
  const [remindMeLater, setRemindMeLater] = React.useState(false)

  React.useEffect(() => {
    document.title = 'New Chat'

    let timer: any
    setInterval(() => {
      console.log('setting isvisible to true')
      setIsVisible(true)
      timer = setTimeout(() => {
        setIsVisible(false)
      }, 10000)
    }, 7000)

    // Set up an interval to call fetchData every 1 minute
    const intervalId = setInterval(() => {}, 60000) // 60000 milliseconds = 1 minute

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId)
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7.5, mt: 12 }}>
        {/* <Header maxWidth='false' /> */}
        <MainSection />
        <HowItWorksSection />
        {/* <OurClientsSection /> */}
        <WhyChooseUsSection />
        <ContactSection />
        <Footer />
      </Box>
      {isVisible && !remindMeLater && currentDate <= targetDate && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '10003',
          }}
          // onClick={handleOfferPlan}
        >
          <img src={offerImage} width='50%' alt='Offer valid till 31st Dec 24' />
          <Stack
            sx={{
              width: '50%',
              bgcolor: '#777',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              style={{ width: '400px', padding: '10px 0px' }}
              onClick={() => {
                setRemindMeLater(true)
              }}
            >
              Remind me later
            </Button>
          </Stack>
        </Box>
      )}
    </>
  )
}

export default HomeIndex
