import React, { Suspense } from 'react'
import { RouterProvider } from 'react-router-dom'
import Routes from './Routes'


const AppRoutes = () => {
  return (
    <Suspense fallback={null}>
      <RouterProvider future={{ v7_startTransition: true }} router={Routes} />
    </Suspense>
  )
}

export default AppRoutes
