import React from 'react'
import { Box, Stack } from '@mui/material'
import NewChatButton from '../Actions/NewChatButton'
// import { FilteredProvider } from '../../../../contexts'
import Categories from './Categories'

// import SearchInput from '../Actions/Search'
import Sidebar from '../Actions/Sidebar'
const Filters = () => {
  // const category = sessionStorage.getItem('category')
  // const [searchOpen, setSearchOpen] = useState<boolean>(false)
  // const [newCategory, setNewCategory] = useState(category)

  return (
    // <FilteredProvider>
    <Stack
      style={{
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      }}
    >
      <Box
        style={{ marginTop: '10px', padding: '0px', boxSizing: 'border-box' }}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <NewChatButton />
        <Sidebar />
      </Box>

      <Stack spacing={2.5} flexGrow={1} justifyContent='space-between' marginBottom='100px'>
        <Categories />
      </Stack>
    </Stack>
    // </FilteredProvider>
  )
}

export default Filters
