import React from 'react'

interface Props {
  message: string
}

const Message = ({ message }: Props) => {
  const mapObj: Record<string, string> = {
    '\\n': '<br />',
    '{"result":"': '',
    '","total_tokens_consumed":2589}': '',
  }

  const renderFormattedText = (text: string) => {
    const initialText: string = message

    const formattedText = initialText.replace(
      /\\n|{"result":"|","total_tokens_consumed":2589}/gi,
      (matched: string): string => {
        return mapObj[matched] || matched
      },
    )

    let modifiedString = ''

    modifiedString = formattedText.replace('}', '')

    modifiedString = modifiedString.replace('{"result":"', '')

    modifiedString = modifiedString.replace('","total_tokens_consumed":', '')

    modifiedString = modifiedString.replace(/\s*\d+\s*$/, '')

    // modifiedString = modifiedString.replace(/\\n\\n/g, '<br/><br/>')

    modifiedString = modifiedString.replace(/\\n/g, ' ')

    const pattern = /\b\d+\.\s(?:[^.]*?(?<!\.)\s(?!.*?\.)|[^.\n])*?:/g

    modifiedString = modifiedString.replace(pattern, '<b>$&</b>')

    // allHistory[0].SearchResult = modifiedString

    return (
      <div style={{ textAlign: 'justify', padding: '30px', width: '650px' }}>
        <div
          style={{ textAlign: 'justify', padding: '30px' }}
          dangerouslySetInnerHTML={{ __html: modifiedString }}
        />
      </div>
    )
  }

  return (
    <>
      <div>{renderFormattedText(message)}</div>
    </>
  )
  // return <div>{message}</div>
}

export default Message
