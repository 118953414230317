import React from 'react'
// import ChatHistory from './ChatHistory'
import OtherSettings from './OtherSettings'
import DeleteAccount from './DeleteAccount'

const DataSetting = () => {
  return (
    <>
      {/* <ChatHistory /> */}
      <OtherSettings />
      <DeleteAccount />
    </>
  )
}

export default DataSetting
