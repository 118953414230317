import React from 'react'
import { IconButton, type IconButtonProps, styled, Tooltip } from '@mui/material'
import { CopyIcon } from '../../../../../components/Icons'
import { useCopyToClipboard } from '../../../../../hooks'
import { useSidebarContext } from '../../../../../contexts/Old_SidebarContext'
import DoneIcon from '@mui/icons-material/Done'

interface Props {
  message: string
}

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  p: 0,
  width: 24,
  height: 24,
  color: theme.palette.background.border,
  '&:hover': {
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
  },
}))

const CopyButton = ({ message }: Props) => {
  const { simplifyText } = useSidebarContext()
  const [copyToClipboard, isCopied]: any = useCopyToClipboard()


  const handleClick = async () => {
    copyToClipboard(simplifyText(message))
    return false
  }

  return (
    <Tooltip title={isCopied ? 'Copied' : 'Copy'} placement='top'>
      <StyledIconButton onClick={handleClick}>
        {!isCopied ? <CopyIcon /> : <DoneIcon />}
      </StyledIconButton>
    </Tooltip>
  )
}

export default CopyButton
