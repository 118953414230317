import React, { useEffect, useState } from 'react'
import baseUrl from '../../../config/baseUrl'
import { Grid, Box, Typography, Button, Stack, Divider, IconButton } from '@mui/material'
import { GoogleOutlinedIcon, LinkedinIcon } from '../../../components/Icons'
import LoginForm from './LoginForm'
import { useGoogleLogin } from '@react-oauth/google'

// import { jwtDecode } from 'jwt-decode'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../contexts'
import SquareLogo from '../../../assets/images/Lawtech icon square.png'
import { useSidebarContext } from '../../../contexts/Old_SidebarContext'
import { useSnackbar } from '../../../components/SnackbarProvider'

const LoginIndex = () => {
  const navigate = useNavigate()
  const showSnackbar = useSnackbar()
  const { setIsLoggedIn } = useSidebarContext()

  const [userDetails, setUserDetails] = useState({
    Email: '',
    FirstName: '',
    LastName: '',
    GoogleId: '',
    Category: '',
  })

  const { isAuthenticated, setAuthenticated } = useAuthContext()

  useEffect(() => {
    document.title = 'Login - LawTech'
  }, [])

  useEffect(() => {
    // This useEffect will be triggered whenever `userData` changes.
    if (userDetails?.Email && userDetails?.GoogleId) {
      googleLogin() // Call googleRagister when `userData` is updated.
    }
  }, [userDetails])

  const googleLogin = async () => {
    try {
      const googleLoginResponse = await fetch(`${baseUrl}/api/auths/googlelogin`, {
        method: 'POST',

        // mode: 'no-cors',
        mode: 'cors',

        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          GoogleId: userDetails.GoogleId,
          Email: userDetails.Email,
        }),
      })

      const googleLoginResponseData = await googleLoginResponse.json()
      if (!googleLoginResponseData?.status) {
        throw new Error(googleLoginResponseData?.message)
      }

      if (googleLoginResponseData.status === true) {
        setAuthenticated(true)

        sessionStorage.setItem('userId', googleLoginResponseData.user.id)
        sessionStorage.setItem('token', googleLoginResponseData.token)
        sessionStorage.setItem('firstName', googleLoginResponseData.user.FirstName)
        sessionStorage.setItem('lastName', googleLoginResponseData.user.LastName)
        sessionStorage.setItem('category', googleLoginResponseData.user.Category)
        sessionStorage.setItem('category2', googleLoginResponseData.user.Category2)
        sessionStorage.setItem('category3', googleLoginResponseData.user.Category3)
        sessionStorage.setItem('email', googleLoginResponseData.user.Email)
        sessionStorage.setItem('phone', googleLoginResponseData?.user?.PhoneNumber)
        sessionStorage.setItem('ExpiryDate', googleLoginResponseData?.user?.SubscriptionExpiryDate)
        sessionStorage.setItem('isPaid', googleLoginResponseData?.user?.IsPaid)
        sessionStorage.setItem('loginCount', googleLoginResponseData?.user?.logincount)
        sessionStorage.setItem('firstLoginDate', googleLoginResponseData?.user?.FirstLoginDate)

        // Date Difference Logic

        // console.log('firstLoginDate', firstLoginDate)
        // const givenDate = firstLoginDate ? new Date(firstLoginDate) : null;
        // const today = new Date();
        // const differenceInMs = givenDate ? today.getTime() - givenDate.getTime() : 0;

        // const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
        // setDateDifferenceInDays(differenceInDays)

        // console.log('Date Difference in days ', differenceInDays)

        setIsLoggedIn(true)
        navigate('/NewChat')
      }
    } catch (error: any) {
      showSnackbar('Google login failed', 'error')
    }
  }

  const signIn = useGoogleLogin({
    onSuccess: async (response: any) => {
      // Handle the Google login response here
      // const data = await JSON.stringify(response)

      const googleToken = response.access_token

      sessionStorage.setItem('token', response.access_token)
      sessionStorage.setItem('googleToken', response.access_token)

      const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${googleToken as string}`,
        },
      })

      const userInfoResponseData = await userInfoResponse.json()

      if (userInfoResponseData?.verified_email) {
        setUserDetails({
          ...userDetails,
          Email: userInfoResponseData?.email,
          FirstName: userInfoResponseData?.given_name,
          LastName: userInfoResponseData?.family_name,
          GoogleId: userInfoResponseData?.id,
          Category: '',
        })

        sessionStorage.setItem('firstName', userInfoResponseData?.given_name)
        sessionStorage.setItem('lastName', userInfoResponseData?.family_name)
        sessionStorage.setItem('email', userInfoResponseData?.email)

        // setAuthenticated(true)
        // setUserDetails({
        //   ...userDetails,
        //   email: userInfoResponseData.email,
        //   googleId: userInfoResponseData.id,
        // })
        // navigate('/')
      }

      // sessionStorage.setItem('google-token', userInfoResponseData.access_token)
      // setAuthenticated(true)
      // navigate('/NewChat')

      // toast.success('Google login response:', response)
    },
    onError: (error: any) => {
      // Handle login failure here

      const errorMessage = (error as Error)?.message || 'Unknown error occurred'
      showSnackbar(`Google login failed: ${errorMessage}`, 'error')
    },
  })

  // const postData = async (userDetails: any) => {
  //   try {
  //     const res = await fetch(`${baseUrl}/api/auths/googlesignup1`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(userDetails),
  //     })

  //     const data = await res.json()

  //     if (!res.ok) {
  //       console.log(data.description)
  //       return
  //     }

  //     navigate('/auth/register')
  //     console.log(data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const { linkedInLogin } = useLinkedIn({
    clientId: '77moe8cjvvq2qq',
    redirectUri: `${baseUrl}/NewChat`,
    onSuccess: (code: any) => {
      // Change from `data.code` to `code`
    },
    // Change from `onFailure` to `onError`
    onError: (error: any) => {
      showSnackbar(error.message, 'error')
    },
  })

  const handleGoogleButtonClick = () => {
    signIn()
  }

  const handleLinedInButtonClick = () => {
    // console.log('window.location.search', window.location.search)
    linkedInLogin()
  }
  return (
    <>
      <Grid
        item
        xs={12}
        md={7}
        sx={{ overflowY: { xs: 'hidden', sm: 'auto' }, height: { xs: 'auto', sm: '100dvh' } }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: '100%',
            py: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', md: 'flex-start' },
              width: '100%',
              maxWidth: 644,
              minHeight: '100%',
              m: '10px auto',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: { xs: 'center', sm: 'flex-start' },
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 0, sm: 2.5 },
                width: '100%',
              }}
            >
              {/* <AppLogoIcon sx={{ width: 91, height: 63 }} /> */}
              <IconButton
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/')
                }}
              >
                <img src={SquareLogo} alt='' height='60px' />
              </IconButton>
              <Box>
                <Typography variant='h3' sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                  Welcome!!
                </Typography>
                <Typography variant='body1'>Login to LawTech Platform</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
                width: '100%',
              }}
            >
              <Stack direction='row' spacing={3} width='100%'>
                {/* <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const credentialDecoded = jwtDecode(credentialResponse.credential as string)
                  // console.log(credentialDecoded)
                }}
                onError={() => {
                  // console.log('Login Failed')
                }}
              /> */}

                <Button
                  variant='contained'
                  onClick={handleGoogleButtonClick}
                  sx={{
                    maxWidth: '100%',
                    height: 51,
                    backgroundColor: 'background.light',
                    color: 'text.secondary',
                    '&:hover, &:active, &:focus': {
                      backgroundColor: 'background.light',
                    },
                  }}
                  endIcon={<GoogleOutlinedIcon />}
                >
                  Google
                </Button>
                {/* <GoogleLoginButton onGoogleLogin={handleGoogleLogin} /> */}

                <Button
                  variant='contained'
                  onClick={handleLinedInButtonClick}
                  sx={{
                    maxWidth: '100%',
                    height: 51,
                    backgroundColor: 'background.light',
                    color: 'text.secondary',
                    '&:hover, &:active, &:focus': {
                      backgroundColor: 'background.light',
                    },
                  }}
                  endIcon={<LinkedinIcon />}
                >
                  LinkedIn
                </Button>
              </Stack>
              <Divider sx={{ width: '80%' }}>
                <Typography variant='subtitle1'>or continue with</Typography>
              </Divider>
            </Box>
            <Box sx={{ width: '100%', pb: 5 }}>
              <LoginForm />
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default LoginIndex
