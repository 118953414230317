import React, { type ChangeEvent, useState, useEffect, useRef } from 'react'
import { useSpeechRecognition } from 'react-speech-recognition'
import { ThreeDots } from 'react-loader-spinner'
import {
  Button,
  Stack,
  Typography,
  CircularProgress,
  InputBase,
  type InputBaseProps,
  styled,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material'
import type { AlertColor } from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import MicPhoneButton from './MicPhoneButton'
// import InputMessage from './InputMessage'
import { CloseIcon, SendIcon } from '../../../../components/Icons'
import { useSidebarContext } from '../../../../contexts/Old_SidebarContext'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import baseUrl from '../../../../config/baseUrl'

const SpeechRecognition = window.SpeechRecognition || (window as any).webkitSpeechRecognition

const StyledInputBase = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderWidth: 0.5,
  borderColor: theme.palette.text.primary,
  '& .MuiInputAdornment-positionEnd': {
    paddingRight: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0), // Smaller padding for 'sm' screen size
    },
  },
  '& .MuiInputBase-input': {
    [theme.breakpoints.down('sm')]: {
      padding: '13px 5px', // Adjust padding for mobile if needed
    },
  },
}))

interface Props {
  setInputMessage: React.Dispatch<React.SetStateAction<string>>
  setAnswer: React.Dispatch<React.SetStateAction<string>>
  aiStorage: any[] // Assuming aiStorage is an array of objects
  setAiStorage: React.Dispatch<React.SetStateAction<any[]>>
}
const SendMessage = ({ setInputMessage, setAnswer, aiStorage, setAiStorage }: Props) => {
  const { transcript } = useSpeechRecognition()
  // const [message, setMessage] = useState<string>('')
  const {
    uniqueString,
    setUniqueString,
    deleteUniqueString,
    isRefFileUploaded,
    setIsRefFileUploaded,
    uploadedFileName,
    setUploadedFileName,
    // uniqueString,

    newChatClicked,
    userStatus,
    handleSearchCount,
    sendData,
    inputMessage,
    message,
    setMessage,
    setLastAskedQuestion,
  } = useSidebarContext()

  const promptRef = useRef<HTMLInputElement>(null)

  const userId: number = sessionStorage.getItem('userId') as unknown as number

  // speech recognition
  const [isListening, setIsListening] = useState(false)
  const [recognition, setRecognition] = useState<SpeechRecognition | null>(null)
  const [placeholder, setPlaceholder] = useState('Enter a prompt here')
  let listeningTimeout: NodeJS.Timeout
  let noInputTimeout: NodeJS.Timeout
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const [uploadStatus, setUploadStatus] = useState('')

  // const token = sessionStorage.getItem('token')
  const [inputQuestion, setInputQuestion] = useState<string>('')
  const [sendIsLoading, setSendIsLoading] = useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [isSendBtnClicked, setIsSendBtnClicked] = useState(false)
  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>(undefined)

  const navigate = useNavigate()

  useEffect(() => {
    // console.log('input message ', inputMessage)
    if (inputMessage?.length > 0) {
      // promptRef.current.value = inputMessage
      setInputQuestion(inputMessage)
    } else {
      setInputQuestion('')
    }
  }, [inputMessage])

  useEffect(() => {
    if (transcript?.length > 0) {
      setMessage(transcript)
      setLastAskedQuestion(transcript)
      setInputQuestion('')
    }
  }, [transcript])

  useEffect(() => {
    selectedFile && onFileUpload()
  }, [selectedFile])

  useEffect(() => {
    if (newChatClicked && promptRef && promptRef.current) {
      promptRef.current.value = ''
    }
  }, [newChatClicked])

  useEffect(() => {
    // message.length > 0 && setInputQuestion(message)
    if (isSendBtnClicked && promptRef?.current !== null) {
      promptRef.current.value = ''
    }
    message.length > 0 &&
      setTimeout(() => {
        setSendIsLoading(false)
        setIsSendBtnClicked(false)
        setInputQuestion('')
      }, 1000)
  }, [message])

  useEffect(() => {
    if (transcript?.length > 0) {
      setInputQuestion(transcript) // Bind transcript directly to input field
      setLastAskedQuestion(transcript)
    }
  }, [])

  const startListening = () => {
    if (!recognition) {
      const newRecognition = new SpeechRecognition()
      newRecognition.lang = 'en-US'
      newRecognition.interimResults = false // You can set this to true if you want partial results as well
      newRecognition.maxAlternatives = 1

      // When speech is recognized, update the text field
      newRecognition.onresult = (event: SpeechRecognitionEvent) => {
        const speechResult = event.results[0][0].transcript
        setInputQuestion(speechResult) // Set the recognized text to `inputQuestion`
        clearTimeout(listeningTimeout) // Clear timeout if input is received
        clearTimeout(noInputTimeout)
        setPlaceholder('Enter a prompt here')
      }

      // Stop listening on recognition end
      newRecognition.onend = () => {
        if (inputQuestion.length === 0) {
          // Check if no speech was recognized
          setPlaceholder('I didn’t understand, could you say it one more time?')
          noInputTimeout = setTimeout(() => {
            setPlaceholder('Enter a prompt here')
          }, 7000) // Reset to default placeholder after 7 seconds
        }
        setIsListening(false)
      }

      // Start the recognition
      newRecognition.start()
      setRecognition(newRecognition)
      setIsListening(true)
    } else {
      recognition.start()
      setIsListening(true)
    }
  }

  const handleMicClick = () => {
    if (isListening) {
      recognition?.stop()
      setIsListening(false)
    } else {
      setInputQuestion('')
      setPlaceholder('Listening...')
      startListening()
      listeningTimeout = setTimeout(() => {
        setPlaceholder('I didn’t understand, could you say it one more time?')
        noInputTimeout = setTimeout(() => {
          setPlaceholder('Enter a prompt here')
        }, 7000)
      }, 10000)
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handleChange = (event: any) => {
    const { value: inputValue } = event.target

    setInputQuestion(inputValue)
    // setMessage(inputValue)
    // setLastAskedQuestion(inputValue)
  }

  const handleEnterKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSendClick()

      // if (inputQuestion.length !== 0) {
      //   // alert('inside handleSendClick')
      //   setIsLoading(true)
      //   const uStatus = await userStatus(userId)
      //   const promptValue = promptRef.current?.value ?? ''
      //   uStatus?.status && handleSearchCount(promptValue, userId)
      //   // setMessage('')
      // }

      // console.log('Enter pressed:', event.key) // Example action
    }
  }

  const handleSendClick = async () => {
    setIsSendBtnClicked(true)
    if (!userId) {
      setSnackbarMessage('Your session has expired please login again')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      setTimeout(() => {
        navigate('/auth/login')
      }, 3500)
    } else if (promptRef?.current?.value?.length === 0) {
      setSnackbarMessage('Input prompt cannot be empty, Enter something!')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      setIsSendBtnClicked(false)
    } else {
      // alert('inside handleSendClick')
      setSendIsLoading(true)
      const uStatus: any = await userStatus(userId)
      if (uStatus?.status === false) {
        setSnackbarMessage('Something is wrong with user status!')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)
        setSendIsLoading(false)
      } else {
        const promptValue = promptRef.current?.value ?? ''
        const handleSearchCountResponse = await handleSearchCount(promptValue, userId)
        if (handleSearchCountResponse) {
          sendData(promptValue)
        } else {
          setSendIsLoading(false)
        }
      }
    }

    // setMessage('')
  }

  const handleAttachFile = () => {
    fileInputRef.current?.click()
  }

  const handleUnloadFile = async () => {
    await deleteUniqueString(uniqueString)
    setIsFileUploading(true)
    // setSendIsLoading(true)
    setSelectedFile(null)

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }

    setUploadedFileName('')
    setIsRefFileUploaded(false)
    setUniqueString('')

    setSnackbarMessage('File removed!')
    setSnackbarSeverity('success')
    setSnackbarOpen(true)

    // setSendIsLoading(false)
    setIsFileUploading(false)
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    // console.log('event.target.files', event.target.files)
    const file = event.target.files?.[0]
    if (file) {
      // console.log('Selected file:', file)
      setSelectedFile(file)
      setUploadedFileName(file.name)
      // You can perform file upload or other actions here
    }
  }

  const onFileUpload = () => {
    setIsFileUploading(true)
    // setSendIsLoading(true)
    if (!selectedFile) {
      setUploadStatus('Please select a file first!')
      setSnackbarMessage('Please select a file first!')
      setSnackbarSeverity('warning')
      setSnackbarOpen(true)
      // setSendIsLoading(false)
      setIsFileUploading(false)
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile)

    const generatedUniqueString = generateUniqueString()
    if (generatedUniqueString) {
      setUniqueString(generatedUniqueString) // Store the unique string in state
      formData.append('uniqueString', generatedUniqueString) // Add the unique string to the form data

      fetch(`${baseUrl}/pyapi/mainqa`, {
        method: 'POST',
        headers: {
          usecase: 'upload',
        },
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((err) => {
              throw new Error(err.error)
            })
          }
          return response.json()
        })
        .then((data) => {
          // console.log('after upload file:', data)
          // console.log('File successfullly uploaded')
          setUploadStatus('File successfully uploaded!')
          setSnackbarMessage('File successfully uploaded!')
          setSnackbarSeverity('success')
          setSnackbarOpen(true)
          // setSendIsLoading(false)
          setIsFileUploading(false)
          setIsRefFileUploaded(true)
        })
        .catch((error) => {
          // console.log(`Error: ${error.message}`)
          setUploadStatus(error?.message)
          setSnackbarMessage('Something went wrong! while file uploading...')
          setSnackbarSeverity('error')
          setSnackbarOpen(true)
          // setSendIsLoading(false)
          setIsFileUploading(false)
        })
    }

    // axios
    //   .post('http://localhost:5000/mainqa', formData, {
    //     headers: { usecase: 'upload' },
    //   })
    //   .then((response) => {
    //     setUploadStatus('File successfully uploaded!')
    //   })
    //   .catch((error) => {
    //     setUploadStatus(`Error: ${error.response.data.error}`)
    //   })
  }

  const generateUniqueString = () => {
    return 'xxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  // console.log('isRefFileUploaded', isRefFileUploaded)

  return (
    <>
      <Stack
        spacing={1}
        position='sticky'
        bottom={0}
        zIndex='1100'
        sx={{
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,0) 13.94%,#F8F8F8 54.73%)',
        }}
      >
        <Stack
          direction='row'
          alignItems='flex-end'
          flexGrow={1}
          sx={{
            bgcolor: '#fafafa',
          }}
        >
          <Stack flexGrow={1} sx={{ marginRight: { xs: '8px', sm: '16px' } }}>
            {/* <ResponseButton /> */}
            {/* <InputMessage
            inputQuestion={inputQuestion}
            onChange={handleChange}
            onKeyDown={(event) => {
              handleEnterKeyPress(event, setInputQuestion)
            }}
          /> */}
            {isFileUploading && (
              <>
                <Box sx={{ position: 'absolute', top: -35, left: 0 }}>
                  <ThreeDots color='#00BFFF' height={50} width={50} />
                </Box>
              </>
            )}
            {isRefFileUploaded && (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <AttachFileIcon sx={{ fontSize: '0.75rem' }} />
                <Typography variant='link' fontWeight='regular' color='navy'>
                  File uploaded : {uploadedFileName}
                </Typography>

                <Tooltip title='Remove File' placement='top'>
                  <IconButton sx={{ ml: '.75rem' }} onClick={handleUnloadFile}>
                    <CloseIcon style={{ color: 'red', fontSize: '0.75rem' }} />
                  </IconButton>
                </Tooltip>
              </span>
            )}
            <StyledInputBase
              disabled={isSendBtnClicked}
              inputRef={promptRef}
              multiline
              // rows={1}
              maxRows={3}
              value={inputQuestion}
              onChange={handleChange}
              onKeyDown={handleEnterKeyPress}
              placeholder={placeholder}
              // endAdornment={isPaid === 'true' ? <MicPhoneButton /> : null}
              endAdornment={
                !sendIsLoading && (
                  <MicPhoneButton micClick={handleMicClick} isListening={isListening} />
                )
              }
              startAdornment={
                <IconButton onClick={handleAttachFile}>
                  <AttachFileIcon />
                  <input
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept='.pdf'
                    onChange={handleFileChange}
                  />
                </IconButton>
              }
              sx={{
                '& .MuiInputBase-input::placeholder': {
                  color: '#666 !important',
                  opacity: 0.4,
                },
              }}
            />
          </Stack>
          <Button
            variant='contained'
            disabled={inputQuestion?.length === 0 || isSendBtnClicked}
            sx={{ minWidth: 50, width: 50, height: 50, p: 0 }}
            onClick={() => {
              handleSendClick() // Optionally, call handleSendClick as well
            }}
          >
            {sendIsLoading ? <CircularProgress color='warning' /> : <SendIcon />}
          </Button>
        </Stack>
        <Typography variant='link' fontWeight='regular'>
          <Typography variant='link' color='error.main'>
            *
          </Typography>{' '}
          This platform may produce inaccurate information, that doesn’t represent LawTech views
        </Typography>
      </Stack>
      {snackbarOpen && (
        <Snackbar
          sx={{ zIndex: '10002' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert elevation={6} onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default SendMessage
