import React, { useState, useRef } from 'react'
// import ReactQuill from 'react-quill-new'
import ReactQuill, { Quill } from 'react-quill'
// import EditorToolbar, { modules, formats } from './EditorToolbar'
import 'react-quill/dist/quill.snow.css'
import './rightDrawer.css'
import baseUrl from '../../../../config/baseUrl'
import PropTypes from 'prop-types'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { marked } from 'marked'
import { useSnackbar } from '../../../../components/SnackbarProvider'
import {
  Alert,
  Grid,
  Snackbar,
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  Radio,
  RadioGroup,
  FormControlLabel,
  // MenuItem
  // TextField,
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
// import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver'
import { pdfExporter } from 'quill-to-pdf'
import * as quillToWord from 'quill-to-word'

const EditablePdfReader = (props) => {
  const fontFamilyArr = [
    'Roboto Condensed',
    'Times New Roman',
    'Calibri',
    'Calibri Light',
    'Sans-Serif',
  ]
  const fonts = Quill.import('attributors/style/font')
  fonts.whitelist = fontFamilyArr
  Quill.register(fonts, true)

  const fontSizeArr = ['10px', '11px', '12px', '14px', '18px', '24px']
  var Size = Quill.import('attributors/style/size')
  Size.whitelist = fontSizeArr
  Quill.register(Size, true)

  const { ans } = props
  const quillRef = useRef(null)
  const [file, setFile] = useState(null)

  const [editorHtml, setEditorHtml] = useState('')

  const [state, setState] = React.useState({ value: null })

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  const showSnackbar = useSnackbar()

  // Loading state
  const [isLoading, setIsLoading] = useState(false)

  const uId = sessionStorage.getItem('userId')

  const [userId, setUserId] = useState('')

  const isSmallScreen = useMediaQuery('(max-width: 600px)')

  const Clipboard = Quill.import('modules/clipboard')
  const [selectedOption, setSelectedOption] = useState('pdf')
  const [isDownloadPressed, setIsDownloadPressed] = useState(false)

  class CustomClipboard extends Clipboard {
    onPaste(e) {
      e.preventDefault()
      const clipboardData = e.clipboardData || window.clipboardData
      const pastedHtml = clipboardData.getData('text/html') || clipboardData.getData('text/plain')

      if (pastedHtml) {
        const quill = this.quill
        const cursorPosition = quill.getSelection().index // Get current cursor position
        quill.clipboard.dangerouslyPasteHTML(cursorPosition, marked(pastedHtml)) // Insert content at cursor
      }
    }
  }

  Quill.register('modules/clipboard', CustomClipboard)

  const handleEditorChange = (content, delta, source, editor) => {
    setEditorHtml(editor.getHTML()) // Get HTML from the editor
  }

  React.useEffect(() => {
    setFile(null)
    formatIncomingAns()
    setState({ ...state, value: ans })
    const handleBeforeUnload = (event) => {
      // event.preventDefault()
      // sessionStorage.setItem('editorHtml', editorHtml)
      // event.returnValue = '' // Required for the prompt to show
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('afterUnload', handleAfterUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const handleAfterUnload = (event) => {
    sessionStorage.setItem('editorHtml', editorHtml)
    event.returnValue = '' // Required for the prompt to show
  }

  React.useEffect(() => {
    setUserId(uId)
  }, [uId])

  React.useEffect(() => {
    if (file === 'testing') {
      file && uploadFile()
    }
  }, [file])

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const formatIncomingAns = () => {
    if (ans) {
      const html = marked(ans) // Convert markdown to HTML using marked
      setEditorHtml(html)
    } // return ans
  }

  // const handleChange = (value) => {
  //   setState({ ...state, value: value })
  // }

  const handlePrint = () => {
    const printWindow = window.open('', '_blank', 'width=900,height=700')

    // Write the quillContent to the new window
    printWindow.document.open()
    printWindow.document.write(`
      <html>
        <head>
          <title>LawTech</title>
          <style>
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${editorHtml}
        </body>
      </html>
    `)
    printWindow.document.close()

    // Trigger print
    printWindow.print()
    printWindow.close()
  }

  const handleSave = () => {
    if (selectedOption === 'pdf') {
      handleDownloadPDF()
    } else {
      handleDownloadDocx()
    }
    setIsDownloadPressed(false)
  }

  const handleDownloadPDF = async () => {
    const quill = quillRef.current.getEditor()
    const delta = quill.getContents()
    if (
      !delta ||
      !delta.ops ||
      delta.ops.length === 0 ||
      delta.ops.every((op) => op.insert.trim() === '')
    ) {
      showSnackbar('The document is empty. Nothing to download.', 'info') // Optional: Display a message
      return
    }
    try {
      const pdfAsBlob = await pdfExporter.generatePdf(delta)
      saveAs(pdfAsBlob, 'document.pdf')
    } catch (error) {
      showSnackbar('An error occurred while generating the PDF.', 'error')
    }
  }

  const handleDownloadDocx = async () => {
    const quill = quillRef.current.getEditor()
    const delta = quill.getContents()

    if (
      !delta ||
      !delta.ops ||
      delta.ops.length === 0 ||
      delta.ops.every((op) => !op.insert.trim())
    ) {
      showSnackbar('The document is empty. Nothing to download.', 'info') // Optional: Display a message
      return
    }

    try {
      const quillToWordConfig = {
        exportAs: 'blob',
      }
      const docAsBlob = await quillToWord.generateWord(delta, quillToWordConfig)
      saveAs(docAsBlob, 'word-export.docx')
    } catch (error) {
      showSnackbar('An error occurred while generating the Word document.', 'error')
    }
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const uploadFile = async () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('userid', userId)
    formData.append('editordocx', file)
    try {
      const response = await fetch(`${baseUrl}/api/editdocx/editdocxupload`, {
        method: 'POST',
        body: formData,
      })

      if (!response.ok) {
        setSnackbarMessage('Oops something went wrong while uploading file...')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)

        throw new Error('Network response was not ok')
      }

      const data = await response.json()

      if (data?.status) {
        setSnackbarMessage('File uploaded successfully')
        setSnackbarSeverity('success')
        setSnackbarOpen(true)
        readUploadedFile(data)
        setFile(null)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error uploading file:', error)
    }
  }

  const readUploadedFile = async (data) => {
    // console.log('readUploadedFile data', data)
    try {
      const getReadFileResponse = await fetch(
        `${baseUrl}/api/editdocx/readEditDocx/${data.data.id}`,
      )
      if (!getReadFileResponse.ok) {
        setSnackbarMessage('Oops something went wrong while reading file...')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)
        throw new Error('something went wrong while reading file...')
      }

      const getReadFileData = await getReadFileResponse.json()
      // console.log('getReadFileData', getReadFileData)

      if (getReadFileData?.status) {
        getReadFileData && setEditorHtml(getReadFileData?.data)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        throw new Error('Something went wrong while reading file...')
      }
    } catch (error) {
      setSnackbarMessage(error)
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      setIsLoading(false)
    }
  }

  EditablePdfReader.propTypes = {
    qs: PropTypes.string.isRequired,
    ans: PropTypes.string.isRequired,
    hId: PropTypes.string.isRequired,
  }

  return (
    <>
      <Box height='100%' position='relative'>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              height: 'calc(100% - 4rem)',
              width: '100%',
              top: '50%',
              left: '45%',
              // padding: '10px',
            }}
          >
            <CircularProgress color='success' />
          </Box>
        )}
        <React.Fragment>
          <Box sx={{ height: '100%', width: '100%' }}>
            <Grid container justifyContent='flex-end' marginBottom='10px'>
              <Button // button to upload file
                startIcon={<LocalPrintshopIcon />}
                variant='contained'
                sx={{
                  height: isSmallScreen ? '30px' : '40px',
                  width: isSmallScreen ? '100px' : '140px',
                  marginRight: '10px',
                  marginTop: isSmallScreen ? '0px' : '10px',
                  color: 'black',
                  fontSize: isSmallScreen ? '10px' : '',
                }}
                onClick={handlePrint}
              >
                Print
              </Button>

              {/* Download button to save file in pdf or docs format */}
              <Button // button to upload file
                startIcon={<DownloadIcon />}
                variant='contained'
                sx={{
                  height: isSmallScreen ? '30px' : '40px',
                  width: isSmallScreen ? '100px' : '140px',
                  marginRight: '10px',
                  marginTop: isSmallScreen ? '0px' : '10px',
                  color: 'black',
                  fontSize: isSmallScreen ? '10px' : '',
                }}
                onClick={() => {
                  setIsDownloadPressed(true)
                }}
              >
                Download
              </Button>
            </Grid>

            {isDownloadPressed && (
              <div>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <label>Select a file format : </label>
                  <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                    <Box
                      sx={{
                        ml: '.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FormControlLabel
                        value='pdf'
                        control={<Radio style={{ color: 'navy' }} />}
                        label='PDF'
                      />
                      <FormControlLabel
                        value='word'
                        control={<Radio style={{ color: 'navy' }} />}
                        label='Word'
                      />
                    </Box>
                  </RadioGroup>
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{
                      height: isSmallScreen ? '30px' : '40px',
                      width: isSmallScreen ? '80px' : '100px',
                      marginRight: '10px',
                      marginLeft: '10px',
                      marginTop: isSmallScreen ? '0px' : '10px',
                      color: 'black',
                      fontSize: isSmallScreen ? '10px' : '',
                    }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>

                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{
                      height: isSmallScreen ? '30px' : '40px',
                      width: isSmallScreen ? '80px' : '90px',
                      marginRight: '10px',
                      marginTop: isSmallScreen ? '0px' : '10px',
                      // color: 'black',
                      fontSize: isSmallScreen ? '10px' : '',
                    }}
                    onClick={() => {
                      setIsDownloadPressed(false)
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </div>
            )}

            <Box>
              {/* <EditorToolbar /> */}
              {/* <ReactQuill
                  theme='snow'
                  style={{ height: '500px', overflowY: 'scroll' }}
                  value={state.value}
                  onChange={handleChange}
                  placeholder={'Write something awesome...'}
                  modules={modules}
                  formats={formats}
                /> */}

              <ReactQuill
                className='my-quill-editor'
                fontSize={12}
                style={{
                  minHeight: 'calc(100dvh - 8rem)',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1',
                  overflowY: 'auto',
                  width: '100%',
                }}
                theme='snow'
                placeholder='Data'
                multirows
                // min-height: calc(100vh - 11rem);
                value={editorHtml}
                ref={quillRef}
                onChange={handleEditorChange}
                modules={{
                  toolbar: [
                    [
                      { header: '1' },
                      { header: '2' },
                      { font: fontFamilyArr },
                      {
                        size: fontSizeArr,
                      },
                    ],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ align: [] }],
                    ['link', 'image'],
                    ['clean'],
                  ],
                  clipboard: { matchVisual: false }, // Disable Quill's visual matching for pasting
                }}
              />
            </Box>
          </Box>
        </React.Fragment>
      </Box>

      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert elevation={6} onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default EditablePdfReader
