import React, { useState } from 'react'
import { IconButton, MobileStepper, styled } from '@mui/material'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material'
// import Tooltip from '../../../../../components/shared/Tooltip';
import Tooltip from '../../components/shared/Tooltip'
// import Messages from '..';
import Messages from './ShaedMessages'
// import Question from '../../Question';
import Question from './SHaredQuestion'

const StyledMobileStepper = styled(MobileStepper)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.secondary.main,
  borderRadius: 4,
  backgroundColor: theme.palette.background.dark,
  padding: 6,
  minWidth: 96,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  p: 0,
  width: 24,
  height: 24,
  color: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

interface StepperProps {
  activeStep: number
  subSearchArray: any[] // Replace with the actual type of your subSearchArray
  handleStepChange: (step: number) => void
}

const Stepper = ({ activeStep, subSearchArray, handleStepChange }: StepperProps) => {




  return (
    <>


      <div>

        <Question
          question={subSearchArray[activeStep]?.SearchTitle}
          questionId={subSearchArray[activeStep]?.id}
          index={activeStep}
        // setAiStorage={setAiStorage} // Pass the necessary props
        />
        <Messages

          answer={subSearchArray[activeStep]?.SearchResult}
          historyId={subSearchArray[activeStep]?.id}
        />
      </div>
    </>
  )
}

export default Stepper
