import React from 'react'
import { Dialog, DialogContent, Typography, Stack, Button } from '@mui/material'
// import { useNavigate } from 'react-router-dom'

interface Props {
  open: boolean
  onClose: () => void
  linkId: any
  deleteShareLink: any
}

const DeleteShareLinkDialog = ({ open, onClose, linkId, deleteShareLink }: Props) => {
  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: 456 } }}>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: '36px 48px',
        }}
      >
        <Stack spacing={3.5}>
          <Typography variant='subtitle2' align='center' color='textSecondary'>
            Are you sure, you want to Delete this share link ?
          </Typography>
          <Stack direction='row' justifyContent='center' spacing={2} width={1}>
            <Button variant='outlined' sx={{ p: 0, maxWidth: 102, height: 44 }} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='contained'
              sx={{ p: 0, maxWidth: 102, height: 44 }}
              onClick={() => {
                deleteShareLink(linkId)
                onClose()
              }}
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteShareLinkDialog
