import React from 'react'
import { Box } from '@mui/material'

import History from './History'
import UpgradeSubscription from './UpgradeSubscription'
import NewChatButton from '../Actions/NewChatButton'
import SearchInput from '../Actions/Search'
import Sidebar from '../Actions/Sidebar'

const HistoryIndex: any = () => {

  return (
    <Box style={{
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    }}>
      <Box display='flex' alignItems='center' justifyContent='space-between' style={{
        marginTop: '10px',
        padding: 0,
        boxSizing: 'border-box'
      }}>
        <NewChatButton />
        <Sidebar />
      </Box>
      <SearchInput />
      <History />
      <UpgradeSubscription />

    </Box >
  )
}

export default HistoryIndex
