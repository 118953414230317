import React, { useEffect, useState } from 'react'
import baseUrl from '../../../config/baseUrl'
import { Link as RouteLink } from 'react-router-dom'
import {
  // CircularProgress,
  Dialog,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Button,
  Stack,
  Divider,
  Link,
} from '@mui/material'
import { UserIcon } from '../../Icons'
import { useSnackbar } from '../../SnackbarProvider'
import CloseIcon from '@mui/icons-material/Close'
import ReactSpeedometer from 'react-d3-speedometer'
import Plans from '../../../pages/ChatBoat/LeftSidebar/History/Plans'
import { useSidebarContext } from '../../../contexts/Old_SidebarContext'

const UpgradeSubscriptionDialog = () => {
  const loginCount = Number(sessionStorage.getItem('loginCount') ?? 0)
  const firstLoginDate = sessionStorage.getItem('firstLoginDate')
  const givenDate = new Date(firstLoginDate ?? '1970-01-01T00:00:00.000Z')
  // console.log('givenDate',givenDate)

  const { upgradeSubscriptionDialog, setUpgradeSubscriptionDialog } = useSidebarContext()
  const [diffInDays, setDiffInDays] = useState(0)
  const [allPlans, setAllPlans] = useState([])

  const [subscribedPlanId, setSubscribedPlanId] = useState(null)
  const [subscribedBasicPlan, setSubscribedBasicPlan] = useState(null) || ''
  const [planExpiryDate, setPlanExpiryDate] = useState(null)

  // const [phoneNumber, setPhoneNumber] = useState<any>(sessionStorage.getItem('phone'))
  const [planUsageDialogOpen, setPlanUsageDialogOpen] = useState(false)
  const [planUsageData, setPlanUsageData] = useState(Object)
  const [tokenConsumed, setTokenConsumed] = useState<string>('')
  const [tokenRemaining, setTokenRemaining] = useState(null)
  const [wordsRemaining, setWordsRemaining] = useState<string | undefined>('')

  // const email = sessionStorage.getItem('email')

  // const userId: number = sessionStorage.getItem('userId') as unknown as number

  const userId: number = Number(sessionStorage.getItem('userId')) ?? 0

  // const subscriptionid = sessionStorage.getItem('subscriptionid')

  // const phone = sessionStorage.getItem('phone')

  // const firstName = sessionStorage.getItem('firstName')

  // const lastName = sessionStorage.getItem('lastName')

  // const fullName = `${firstName as string} ${lastName as string}`

  const token = sessionStorage.getItem('token')

  const isPaid: boolean = sessionStorage.getItem('isPaid') === 'true'
  const showSnackbar = useSnackbar()
  // const [successfullyDialog, setSuccessfullyDialog] = useState<any>(false)
  useEffect(() => {
    // daystime logic
    // console.log('givenDate', givenDate)
    // givenDate === null ? new Date(givenDate) : data?.user?.FirstLoginDate
    const today = new Date()
    const differenceInMs = today.getTime() - (!givenDate.getTime() ? 0 : givenDate.getTime())
    // console.log('differenceInMs',differenceInMs);
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24))
    // console.log('differenceInDays', differenceInDays)
    if (differenceInDays > 0 && differenceInDays < 8) {
      setDiffInDays(differenceInDays)
    }
    if (differenceInDays >= 8) {
      setDiffInDays(10)
    }
  }, [])

  useEffect(() => {
    // console.log('upgradeSubscriptionDialog',upgradeSubscriptionDialog,diffInDays)
    if (upgradeSubscriptionDialog && diffInDays >= 0) {
      // console.log('diffInDays',diffInDays)
      getAllPlans()
      getSubscribedPlan()
      getProfileDetails()
    }
  }, [diffInDays])

  // useEffect(() => {
  //   const sortedAllPlans = []
  //   if (allPlans.length > 0) {
  //     sortedAllPlans = allPlans.sort((a, b) => a.id - b.id)
  //   }
  // }, [allPlans])

  const getAllPlans = async () => {
    // console.log('GETALLPLANS')
    const response = await fetch(`${baseUrl}/api/admin/plan/allplans`)
    const data = await response.json()
    // console.log('data.data', data?.data)
    const sortedPlans = data?.data.sort((a: any, b: any) => a.id - b.id)

    if (loginCount < 8) {
      sortedPlans && setAllPlans(sortedPlans)
    } else {
      const sPlans = sortedPlans.filter((plan: any) => plan?.PlanName !== 'Introductory Offer')
      // sPlans && console.log('sPlans', sPlans)
      sPlans && setAllPlans(sPlans)
    }

    // const plans = sortedData.filter((plan: any) => {
    //   if (dateDifferenceInDays && dateDifferenceInDays >= 7 && plan === 'Introductory Offer') {
    //     return null
    //   } else if (dateDifferenceInDays && dateDifferenceInDays < 7 && plan === 'Introductory Offer') {
    //     return plan
    //   } else {
    //     return plan
    //   }
    // })
  }

  const getSubscribedPlan = async () => {
    try {
      if (userId !== null) {
        const res = await fetch(`${baseUrl}/api/users/usercurrentplan/${userId}`)
        const data = await res.json()

        if (!res.ok) {
          showSnackbar(data.description, 'warning')

          return
        }

        if (data) {
          if (data?.status) {
            setSubscribedPlanId(data.data)
          }
        }
      }
    } catch (error: any) {
      showSnackbar(error.message, 'error')
    }
  }

  const getProfileDetails = async () => {
    try {
      const response: any = await fetch(`${baseUrl}/api/users/ProfileDetails/${userId} `, {
        method: 'GET',
        mode: 'cors',

        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
      })

      const data = await response.json()

      if (data.status === true) {
        if (!data?.personaldata?.PhoneNumber) {
          showSnackbar(
            'Please update your mobile number by going to Profile, Settings, Contact no... update',
            'info',
          )
          // setUpgradeSubscriptionDialog(false)
          return
        }
        // console.log('profile data', data)
        setTokenRemaining(data?.personaldata?.Tokens?.toLocaleString('en-US'))
        const wr = Math.ceil((data?.personaldata?.Tokens * 75) / 100).toLocaleString('en-US')
        setWordsRemaining(wr)
        setSubscribedBasicPlan(data?.personaldata?.freesubscriptionplan)
        setPlanExpiryDate(data?.personaldata?.SubscriptionExpiryDate)
      } else {
        showSnackbar('Error in getting details', 'error')
      }
    } catch (error) {
      showSnackbar('An error occurred while submitting the form data', 'error')
    }
  }

  // ------------------------------- getConsumption ---------------
  const getConsumption = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/users/barometerapi/${userId ?? ''}`)
      const data = await res.json()
      console.log('data', data)
      if (!res.ok) {
        showSnackbar(data.message, 'warning')
        return
      }

      if (data) {
        setPlanUsageData(data)
        setTokenConsumed(data?.data ? data?.data?.toLocaleString('en-US') : 0)
      }
    } catch (error: any) {
      // console.log(error)
      showSnackbar(error, 'error')
    }
  }

  const openPlanUsageDialog = () => {
    getConsumption()
    setPlanUsageDialogOpen(true)
  }

  const closePlanUsageDialog = () => {
    setPlanUsageDialogOpen(false)
  }

  // const redirect = () => {
  //   const paymentWindow = window.open(
  //     'https://pay.openai.com/c/pay/cs_live_a1toPHUdFzMQRDm5rL63ISMOQsAjpxvCywePZI9cJMLcuEIujqqvJkw2PD#fid1d2BpamRhQ2prcSc%2FJ0xrcWB3JykndnBndmZ3bHVxbGprUGtsdHBga2B2dkBrZGdpYGEnP3F3cGApJ2R1bE5gfCc%2FJ3VuWmlsc2BaMDRNSndWckYzbTRrfUJqTDZpUURiV29cU3d%2FMWFQNmNTSmRnfEZmTlc2dWdAT2JwRlNEaXRGfWF9RlBzaldtNF1ScldkZlNsanNQNm5JTnN1bm9tMkx0blI1NWxdVHZvajZrJyknY3dqaFZgd3Ngdyc%2FcXdwYCknaWR8anBxUXx1YCc%2FJ3Zsa2JpYFpscWBoJyknYGtkZ2lgVWlkZmBtamlhYHd2Jz9xd3BgeCUl',
  //     '_blank',
  //   )
  //   // window.paymentWindow = paymentWindow
  // }

  // const handleClose = () => {
  //   setSuccessfullyDialog(false)
  // }

  // const checkTokenConsumption = () => {
  //   // fetch(`${baseUrl}/api/admin/eachusertoken`)
  // }

  // useEffect(() => {
  // if (phoneNumber === 'null') {
  //   toast.error('Please enter a contact number', {
  //     position: toast.POSITION.TOP_CENTER,
  //   })
  // }
  // }, [open, phoneNumber])

  // const handleUpgradePlan = async () => {
  //   try {
  //     const subscriptionResonse = await fetch(`${baseUrl}/api/order/create`, {
  //       method: 'POST',
  //       mode: 'cors',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         authorization: token as string,
  //       },
  //       body: JSON.stringify({
  //         userid: userId,
  //         amount: 20,
  //         subscriptionid: subscriptionid,
  //       }),
  //     })

  //     const subscriptionResonseData = await subscriptionResonse.json()

  //     if (subscriptionResonse.ok) {
  //       window.open(subscriptionResonseData.payment_link, '_blank')
  //     }
  //   } catch (error: any) {
  //     toast.error('Error in upgrade subscription : ', error.message)
  //   }
  // }

  const renderPlanUsageDialog = () => (
    <Dialog
      open={planUsageDialogOpen}
      fullWidth
      onClose={closePlanUsageDialog}
      sx={{ zIndex: '10003' }}
    >
      <DialogContent
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography variant='h5'>Hello! Your Usage Consumption in % (Percentage).</Typography>
        <IconButton onClick={closePlanUsageDialog}>
          <CloseIcon color='warning' />
        </IconButton>
      </DialogContent>

      <div
        style={{
          marginTop: '10px',
          padding: '20px',
          width: '100%',
          height: '325px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          zIndex: 5,
        }}
      >
        <h4>Consumption Scale</h4>
        <ReactSpeedometer
          maxValue={100}
          value={planUsageData?.data?.toFixed(2)}
          // value={Number(planUsageData?.data) + 200}
          ringWidth={10}
          customSegmentStops={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          needleColor='#F54E4E'
          needleHeightRatio={0.8}
          currentValueText={`Plan Usage :  ${tokenConsumed} %`}
          needleTransitionDuration={2000}
        />
        {/* <h6>{planUsageData?.data}%</h6> */}
      </div>
    </Dialog>
  )

  return (
    <>
      <Dialog open={upgradeSubscriptionDialog} fullWidth PaperProps={{ sx: { maxWidth: 646 } }}>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: 4,
          }}
        >
          <Stack spacing={3} width={1} position='relative'>
            <Stack spacing={2} width={1}>
              <Box
                // onClick={() => {
                //   redirect()
                // }}
                display='flex'
                alignItems='center'
                gap={2}
                width={1}
              >
                <Box display='flex' alignItems='center' color='text.secondary'>
                  <UserIcon sx={{ width: 20, height: 20 }} />
                </Box>
                <Typography
                  variant='body2'
                  fontWeight={600}
                  color='textSecondary'
                  sx={{ bgColor: 'white' }}
                >
                  Upgrade Subscription
                </Typography>
              </Box>
              <Divider component='div' sx={{ borderColor: 'background.border' }} />
              <Box
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography>Token remaining : {tokenRemaining}</Typography>
                <Typography>Words remaining : {wordsRemaining}</Typography>
              </Box>
            </Stack>

            <Stack
              style={{ margin: '5px auto' }}
              spacing={3}
              direction='row'
              width={1}
              overflow='auto'
            >
              <Stack style={{ margin: '30px auto' }} spacing={3} direction='row'>
                {allPlans?.map((plan, index) => {
                  return (
                    <Plans
                      key={index}
                      onClose={() => {
                        setUpgradeSubscriptionDialog(false)
                      }}
                      plan={plan}
                      subscribedPlanId={subscribedPlanId}
                      subscribedBasicPlan={subscribedBasicPlan}
                      planExpiryDate={planExpiryDate}
                    />
                  )
                })}
                <Divider component='div' sx={{ borderColor: 'background.border' }} />
              </Stack>
            </Stack>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems='center'
              justifyContent='space-between'
              textAlign='center'
              spacing={3}
              width={1}
            >
              <Link
                component={RouteLink}
                to='/faq/need-help-with-a-billing-issue'
                underline='none'
                sx={{ '&:hover': { textDecoration: 'none', color: 'inherit' } }}
              >
                Need help with a billing issue
              </Link>

              <div style={{ cursor: 'pointer' }}>
                <Link
                  underline='none'
                  onClick={() => {
                    openPlanUsageDialog()
                  }}
                  sx={{ '&:hover': { textDecoration: 'none', color: 'inherit' } }}
                >
                  Plan Usage
                </Link>
              </div>

              {isPaid && (
                <Button
                  variant='outlined'
                  sx={{ p: 0, maxWidth: 114, height: 44 }}
                  onClick={() => {
                    setUpgradeSubscriptionDialog(false)
                  }}
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </Stack>
        </DialogContent>
        {renderPlanUsageDialog()}
      </Dialog>
    </>
  )
}

export default UpgradeSubscriptionDialog
